import React from 'react';
import logo from '../../img/logo-box.png'
import '../../Estilos/Animations.css'
import { Link } from 'react-router-dom';

const HomeLogOff = () => {
    return (
        <div>
            <svg
                id="wave"
                style={{ transform: 'rotate(180deg)', transition: '0.3s' }}
                viewBox="0 0 1440 490"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>

                    <linearGradient
                        id="sw-gradient-0"
                        x1="0"
                        x2="0"
                        y1="1"
                        y2="0"
                    >

                        <stop
                            stopColor="rgba(0, 133, 255, 1)"
                            offset="0%"
                        >
                        </stop>
                        <stop
                            stopColor="rgba(37.081, 79.334, 122.406, 1)"
                            offset="100%"
                        >
                        </stop>
                    </linearGradient>
                </defs>

                <path
                    style={{ transform: 'translate(0, 0px)', opacity: '1', animation: 'wave-animation 2s 1' }}
                    fill="url(#sw-gradient-0)"
                    d="M0,392L26.7,375.7C53.3,359,107,327,160,302.2C213.3,278,267,261,320,277.7C373.3,294,427,343,480,351.2C533.3,359,587,327,640,269.5C693.3,212,747,131,800,89.8C853.3,49,907,49,960,57.2C1013.3,65,1067,82,1120,114.3C1173.3,147,1227,196,1280,179.7C1333.3,163,1387,82,1440,106.2C1493.3,131,1547,261,1600,294C1653.3,327,1707,261,1760,220.5C1813.3,180,1867,163,1920,204.2C1973.3,245,2027,343,2080,351.2C2133.3,359,2187,278,2240,261.3C2293.3,245,2347,294,2400,269.5C2453.3,245,2507,147,2560,163.3C2613.3,180,2667,310,2720,334.8C2773.3,359,2827,278,2880,212.3C2933.3,147,2987,98,3040,73.5C3093.3,49,3147,49,3200,73.5C3253.3,98,3307,147,3360,171.5C3413.3,196,3467,196,3520,204.2C3573.3,212,3627,229,3680,253.2C3733.3,278,3787,310,3813,326.7L3840,343L3840,490L3813.3,490C3786.7,490,3733,490,3680,490C3626.7,490,3573,490,3520,490C3466.7,490,3413,490,3360,490C3306.7,490,3253,490,3200,490C3146.7,490,3093,490,3040,490C2986.7,490,2933,490,2880,490C2826.7,490,2773,490,2720,490C2666.7,490,2613,490,2560,490C2506.7,490,2453,490,2400,490C2346.7,490,2293,490,2240,490C2186.7,490,2133,490,2080,490C2026.7,490,1973,490,1920,490C1866.7,490,1813,490,1760,490C1706.7,490,1653,490,1600,490C1546.7,490,1493,490,1440,490C1386.7,490,1333,490,1280,490C1226.7,490,1173,490,1120,490C1066.7,490,1013,490,960,490C906.7,490,853,490,800,490C746.7,490,693,490,640,490C586.7,490,533,490,480,490C426.7,490,373,490,320,490C266.7,490,213,490,160,490C106.7,490,53,490,27,490L0,490Z"
                >
                </path>
                <defs>
                    <linearGradient
                        id="sw-gradient-1"
                        x1="0"
                        x2="0"
                        y1="1"
                        y2="0"
                    >
                        <stop
                            stopColor="rgba(0, 133, 255, 1)"
                            offset="0%"
                        >
                        </stop>
                        <stop
                            stopColor="rgba(73.471, 145.335, 181.602, 1)"
                            offset="100%"
                        >
                        </stop>
                    </linearGradient>
                </defs>
                <path
                    style={{ transform: 'translate(0, 50px)', opacity: '0.9', animation: 'wave-animation 2s 1' }}
                    fill="url(#sw-gradient-1)"
                    d="M0,392L26.7,375.7C53.3,359,107,327,160,334.8C213.3,343,267,392,320,359.3C373.3,327,427,212,480,196C533.3,180,587,261,640,277.7C693.3,294,747,245,800,196C853.3,147,907,98,960,81.7C1013.3,65,1067,82,1120,106.2C1173.3,131,1227,163,1280,155.2C1333.3,147,1387,98,1440,81.7C1493.3,65,1547,82,1600,98C1653.3,114,1707,131,1760,122.5C1813.3,114,1867,82,1920,57.2C1973.3,33,2027,16,2080,81.7C2133.3,147,2187,294,2240,351.2C2293.3,408,2347,376,2400,359.3C2453.3,343,2507,343,2560,310.3C2613.3,278,2667,212,2720,212.3C2773.3,212,2827,278,2880,253.2C2933.3,229,2987,114,3040,65.3C3093.3,16,3147,33,3200,32.7C3253.3,33,3307,16,3360,24.5C3413.3,33,3467,65,3520,81.7C3573.3,98,3627,98,3680,155.2C3733.3,212,3787,327,3813,383.8L3840,441L3840,490L3813.3,490C3786.7,490,3733,490,3680,490C3626.7,490,3573,490,3520,490C3466.7,490,3413,490,3360,490C3306.7,490,3253,490,3200,490C3146.7,490,3093,490,3040,490C2986.7,490,2933,490,2880,490C2826.7,490,2773,490,2720,490C2666.7,490,2613,490,2560,490C2506.7,490,2453,490,2400,490C2346.7,490,2293,490,2240,490C2186.7,490,2133,490,2080,490C2026.7,490,1973,490,1920,490C1866.7,490,1813,490,1760,490C1706.7,490,1653,490,1600,490C1546.7,490,1493,490,1440,490C1386.7,490,1333,490,1280,490C1226.7,490,1173,490,1120,490C1066.7,490,1013,490,960,490C906.7,490,853,490,800,490C746.7,490,693,490,640,490C586.7,490,533,490,480,490C426.7,490,373,490,320,490C266.7,490,213,490,160,490C106.7,490,53,490,27,490L0,490Z"
                >
                </path>

                <defs>
                    <linearGradient id="sw-gradient-2" x1="0" x2="0" y1="1" y2="0">
                        <stop
                            stopColor="rgba(0, 133, 255, 1)"
                            offset="0%"
                        >
                        </stop>

                        <stop
                            stopColor="rgba(137.431, 220.651, 254.459, 1)"
                            offset="100%"
                        >
                        </stop>
                    </linearGradient>
                </defs>
                <path
                    style={{ transform: 'translate(0, 100px)', opacity: '0.8', animation: 'wave-animation 2s 1' }}
                    fill="url(#sw-gradient-2)"
                    d="M0,98L26.7,81.7C53.3,65,107,33,160,81.7C213.3,131,267,261,320,310.3C373.3,359,427,327,480,326.7C533.3,327,587,359,640,367.5C693.3,376,747,359,800,318.5C853.3,278,907,212,960,196C1013.3,180,1067,212,1120,253.2C1173.3,294,1227,343,1280,326.7C1333.3,310,1387,229,1440,204.2C1493.3,180,1547,212,1600,253.2C1653.3,294,1707,343,1760,367.5C1813.3,392,1867,392,1920,359.3C1973.3,327,2027,261,2080,212.3C2133.3,163,2187,131,2240,155.2C2293.3,180,2347,261,2400,318.5C2453.3,376,2507,408,2560,367.5C2613.3,327,2667,212,2720,163.3C2773.3,114,2827,131,2880,155.2C2933.3,180,2987,212,3040,245C3093.3,278,3147,310,3200,326.7C3253.3,343,3307,343,3360,318.5C3413.3,294,3467,245,3520,220.5C3573.3,196,3627,196,3680,196C3733.3,196,3787,196,3813,196L3840,196L3840,490L3813.3,490C3786.7,490,3733,490,3680,490C3626.7,490,3573,490,3520,490C3466.7,490,3413,490,3360,490C3306.7,490,3253,490,3200,490C3146.7,490,3093,490,3040,490C2986.7,490,2933,490,2880,490C2826.7,490,2773,490,2720,490C2666.7,490,2613,490,2560,490C2506.7,490,2453,490,2400,490C2346.7,490,2293,490,2240,490C2186.7,490,2133,490,2080,490C2026.7,490,1973,490,1920,490C1866.7,490,1813,490,1760,490C1706.7,490,1653,490,1600,490C1546.7,490,1493,490,1440,490C1386.7,490,1333,490,1280,490C1226.7,490,1173,490,1120,490C1066.7,490,1013,490,960,490C906.7,490,853,490,800,490C746.7,490,693,490,640,490C586.7,490,533,490,480,490C426.7,490,373,490,320,490C266.7,490,213,490,160,490C106.7,490,53,490,27,490L0,490Z"
                >
                </path>
            </svg >
            <div className='logo' style={{ margin: "0px" }}>
                <img
                    src={logo}
                    style={{
                        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)',
                        borderRadius: '40px',
                        position: 'absolute',
                        top: '15vw',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        height: "20vw",
                        zIndex: 1,
                    }}
                    id='logo-img'
                    alt="Logo de Kyklos"
                />
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                marginTop: "-7vw",
                animation: "appear 2s forwards"
            }}>
                <div>
                    <h1 style={{
                        fontFamily: "Dosis",
                    }}>
                        ¡Bienvenido!
                    </h1>
                    <p style={{
                        fontFamily: "Dosis",
                        fontSize: "120%",
                    }}>
                        Si ya estás registrado, selecciona la opción <b>Ingresar</b>
                    </p>
                    <p style={{
                        fontFamily: "Dosis",
                        fontSize: "120%",
                    }}>
                        Si es la primera vez que ingresas al sitio, selecciona la opción <b>Registrarme</b> y pídele a tu jefe que te dé los permisos correspondientes
                    </p>
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
                <Link
                    to={'/login'}
                    style={{
                        backgroundColor: "#0085FE",
                        borderRadius: "5px",
                        color: "white",
                        padding: "10px 20px",
                        margin: "10px",
                        textAlign: "center",
                        fontFamily: "Dosis",
                        width: "10vw",
                        fontSize: "120%",
                        fontWeight: "bold",
                        zIndex: 2,
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                        animation: "appear 2s forwards",
                        textDecoration: "none"
                    }}
                    onMouseOver={() => {
                        const imgElement = document.getElementById('login-button');
                        if (imgElement) {
                            imgElement.style.boxShadow = '0px 0px 5px rgba(0, 0, 0, 1)';
                        }
                    }}
                    onMouseOut={() => {
                        const imgElement = document.getElementById('login-button');
                        if (imgElement) {
                            imgElement.style.boxShadow = '0px 0px 5px rgba(0, 0, 0, 0.3)';
                        }
                    }}
                    id='login-button'>
                    Ingresar
                </Link>
                <Link
                    to={'/signup'}
                    style={{
                        backgroundColor: "#0085FE",
                        borderRadius: "5px",
                        color: "white",
                        padding: "10px 20px",
                        margin: "10px",
                        textAlign: "center",
                        fontFamily: "Dosis",
                        width: "10vw",
                        fontSize: "120%",
                        fontWeight: "bold",
                        marginLeft: "10%",
                        zIndex: 2,
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                        animation: "appear 2s forwards",
                        textDecoration: "none"
                    }}
                    onMouseOver={() => {
                        const imgElement = document.getElementById('signup-button');
                        if (imgElement) {
                            imgElement.style.boxShadow = '0px 0px 5px rgba(0, 0, 0, 1)';
                        }
                    }}
                    onMouseOut={() => {
                        const imgElement = document.getElementById('signup-button');
                        if (imgElement) {
                            imgElement.style.boxShadow = '0px 0px 5px rgba(0, 0, 0, 0.3)';
                        }
                    }}
                    id='signup-button'
                >
                    Registrarme
                </Link>
            </div>

        </div>
    );

};

export default HomeLogOff;
