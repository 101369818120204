import React from "react";
import '../../Estilos/PostForm.css';
import InputResTypes from './InputResidueTypes';


function InputSeg(props) {

    return (
        <div className="input-div">
            <InputResTypes />
            <div className="peso-container">
                <input type="number" name="residueWeight" className="weight input"/>
            </div>
        </div>
    );
}

export default InputSeg;