import React, { useState, useEffect } from "react";
import apiClient from "../Services/apiClient";
import Recoleccion from "./Recoleccion";
import moment from 'moment-timezone';
import '../../Estilos/Filters.css'
import '../../Estilos/CommonComponents.css'

function armarRec(params) {
  const fecha = new Date(params["collected_at"])
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const fecha_formateada = fecha.toLocaleDateString('es-CL', options)

  return (
    <Recoleccion
      key={params["id"]}
      id={params["id"]}
      fecha={fecha_formateada}
      sucursal={params["location"]["address"]}
      campaña={params["campaign"]["name"]}
    />
  );
}

export default function Recolecciones() {
  const [recolecciones, setRecs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recoleccionesPerPage] = useState(10);
  const [filtroFecha, setFiltroFecha] = useState('');
  const [filtroSucursal, setFiltroSucursal] = useState('');
  const [filtroCampaña, setFiltroCampaña] = useState('');

  useEffect(() => {
    apiClient({
      method: "GET",
      url: "/service_schedules?all=true&status=weighted",
      headers: {},
    })
      .then(function (response) {
        setRecs(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleChangeFiltroFecha = (event) => {
    setFiltroFecha(event.target.value);
    setCurrentPage(1); // Reiniciar a la primera página después de cambiar el filtro
  };

  const handleChangeFiltroSucursal = (event) => {
    setFiltroSucursal(event.target.value);
    setCurrentPage(1); // Reiniciar a la primera página después de cambiar el filtro
  }

  const handleChangeFiltroCampaña = (event) => {
    setFiltroCampaña(event.target.value);
    setCurrentPage(1); // Reiniciar a la primera página después de cambiar el filtro
  }

  const recoleccionesFiltradas = recolecciones.filter((request) => {
    const requestDate = moment.tz(request.collected_at, 'America/Santiago').toDate();
    const fechaFiltro = moment.tz(filtroFecha, 'America/Santiago').toDate();
    const offset = requestDate.getTimezoneOffset();
    requestDate.setMinutes(requestDate.getMinutes() - offset);

    if (!filtroFecha && !filtroSucursal && !filtroCampaña) {
      return true;
    } else if (!filtroFecha && filtroSucursal && !filtroCampaña) {
      console.log(filtroSucursal.toLowerCase())
      return request.location.address.toLowerCase().includes(filtroSucursal.toLowerCase());
    } else if (!filtroFecha && !filtroSucursal && filtroCampaña) {
      return request.campaign.name.toLowerCase().includes(filtroCampaña.toLowerCase());
    } else if (filtroFecha && !filtroSucursal && !filtroCampaña) {
      return (
        requestDate.getFullYear() === fechaFiltro.getFullYear() &&
        requestDate.getMonth() === fechaFiltro.getMonth() &&
        requestDate.getDate() === fechaFiltro.getDate()
      );
    } else if (filtroFecha && !filtroSucursal && filtroCampaña) {
      return (
        requestDate.getFullYear() === fechaFiltro.getFullYear() &&
        requestDate.getMonth() === fechaFiltro.getMonth() &&
        requestDate.getDate() === fechaFiltro.getDate() &&
        request.campaign.name.toLowerCase().includes(filtroCampaña.toLowerCase())
      );
    } else if (filtroFecha && filtroSucursal && !filtroCampaña) {
      return (
        requestDate.getFullYear() === fechaFiltro.getFullYear() &&
        requestDate.getMonth() === fechaFiltro.getMonth() &&
        requestDate.getDate() === fechaFiltro.getDate() &&
        request.location.address.toLowerCase().includes(filtroSucursal.toLowerCase())
      );
    } else if (!filtroFecha && filtroSucursal && filtroCampaña) {
      return (
        request.location.address.toLowerCase().includes(filtroSucursal.toLowerCase()) &&
        request.campaign.name.toLowerCase().includes(filtroCampaña.toLowerCase())
      );
    };
  });

  const totalRecolecciones = recoleccionesFiltradas.length;

  // Ajustar la página actual si está fuera de rango después de aplicar un filtro
  useEffect(() => {
    const lastPage = Math.ceil(totalRecolecciones / recoleccionesPerPage);
    if (currentPage > lastPage) {
      setCurrentPage(lastPage);
    }
  }, [totalRecolecciones, recoleccionesPerPage, currentPage]);

  const indexOfLastRecoleccion = currentPage * recoleccionesPerPage;
  const indexOfFirstRecoleccion = indexOfLastRecoleccion - recoleccionesPerPage;
  const currentRecolecciones = recoleccionesFiltradas.slice(indexOfFirstRecoleccion, indexOfLastRecoleccion);

  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1); // Establecer la página actual en 1 después de cargar las recolecciones
  }, [recolecciones]);

  return (
    <div className="table-container">

      <div className='filters'>
        <div className='filter'>
          <label htmlFor="filtroFecha">Filtrar por fecha:</label>
          <input type="date" id="filtroFecha" onChange={handleChangeFiltroFecha} />
        </div>

        <div className='filter'>
          <label htmlFor="filtroSucursal">Filtrar por sucursal:</label>
          <input type="text" id="filtroSucursal" onChange={handleChangeFiltroSucursal} />
        </div>

        <div className='filter'>
          <label htmlFor="filtroCampaña">Filtrar por campaña:</label>
          <input type="text" id="filtroSucursal" onChange={handleChangeFiltroCampaña} />
        </div>
      </div>

      <div className="table">
        <div className="row" id="header">
          <div className="header">Fecha de recolección</div>
          <div className="header">Sucursal</div>
          <div className="header">Campaña</div>
          <div className="header">Ver bolsas</div>
        </div>
        {currentRecolecciones.map((rec) => armarRec(rec))}
      </div>
      {totalRecolecciones > recoleccionesPerPage && (
        <div className="pagination">
          <ul className="pagination-list">
            {Array.from({ length: Math.ceil(totalRecolecciones / recoleccionesPerPage) }, (_, index) => (
              <li
                key={index}
                className={`pagination-item ${index + 1 === currentPage ? "active" : ""}`}
                onClick={() => handleChangePage(index + 1)}
              >
                {index + 1}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
