import { Link } from 'react-router-dom';
import '../../Estilos/NotAuth.css';

export default function NotAuth({ token, setToken }) {

  function handleLogOut() {
    setToken(null);
    localStorage.clear();
  }

  return (
    <div className='text-box'>
      <h1>No estás autorizado para entrar a la aplicación.</h1>
      <h3>Por favor contacta a algún administrador para obtener permisos</h3>
    </div>
  );
}