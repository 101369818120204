import React from "react";
import apiClient from "../Services/apiClient";
import '../../Estilos/Bolsa.css';
import DeleteIcon from '@mui/icons-material/Delete';


async function EliminarBolsa(sale, bolsa) {
  try {
    await apiClient.delete(`/sales/${sale}/sales_bags/${bolsa}`);
    return true;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      alert("Token Inválido. No puedes realizar esta acción.");
    } else {
      alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      console.log(error);
    }
    return false;
  }
}

function BolsaDisp(props) {

  return (
    <div className="row">
      <p className="cell" id="bolsas-row">{props.tipo}</p>
      <p className="cell" id="bolsas-row">{props.peso}</p>
      <div className="cell" id="bolsas-row">
        <DeleteIcon className="config-button" onClick={async () => {
          if (window.confirm('¿Está seguro que desea eliminar esta bolsa?')) {
            const respuesta = await EliminarBolsa(props.idsale, props.idbolsa);
            if (respuesta) {
              window.location.reload(true);
            }
            else {
              alert("HUBO UN ERROR");
            }
          }
        }} />
      </div>
    </div>
  );
}

export default BolsaDisp;