import React, { useState, useEffect } from "react";
import { orderStats } from "../Helpers/Stats";
import apiClient from "../Services/apiClient";
import { Chart } from "react-google-charts";
import '../../Estilos/Estadisticas/estadisticas.css'
import '../../Estilos/PostForm.css';
import RecyclingOutlinedIcon from '@mui/icons-material/RecyclingOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { pastelColors, chunkArray } from "../Helpers/Stats"

export default function RecuperacionAcumulada() {
  const [clients, setClients] = useState([]);
  const [clientID, setClientID] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [stats, setStats] = useState();

  async function getStatistics() {
    try {
      const response = await apiClient.get("/statistics?client=" + clientID + "&year=0&month=0");
      setStats(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  useEffect(() => {
    getStatistics();
  }, [clientID]);

  const options = {
    title: "Peso por Material",
    is3D: true,
    backgroundColor: "transparent",
    chartArea: { 'width': '100%', 'height': '100%' },
    colors: pastelColors,
    pieSliceText: "value",
    pieSliceTextStyle: {
      fontName: "Dosis",
      color: "black",
      fontSize: 15,
    },
    legend: {
      position: 'right',
      textStyle: {
        fontName: 'Dosis',
        fontSize: 16,
        color: 'black',
      },
    }
  }

  async function fetchClients() {
    try {
      const response = await apiClient.get("/clients/");
      setClients(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  const displayClientOptions = clients.map((client) => {
    return (
      <option key={client.id} value={client.id}>{client.name}</option>
    )
  })

  useEffect(() => {
    fetchClients();
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  let orderedStats = orderStats(stats["materials"]);
  let orderedMaterials = orderedStats[0];
  let statsData = orderedStats[1];

  const materialsChunks = chunkArray(orderedMaterials, 3);

  const selectedClient = clients.find((client) => client.id === parseInt(clientID));

  let headingText = "Recuperación Acumulada Total [Kg]";
  if (selectedClient) {
    headingText = `Recuperación Acumulada de ${selectedClient.name} [Kg]`;
  }

  return (
    <div className="stats-container">
      <h2>{headingText}</h2>
      <hr></hr>

      <div className="elements">
        <div className="element">
          <div className="select-style">
            <select name="clients" id="clients" onChange={(e) => { setClientID(e.target.value) }}>
              <option value="0">Seleccione un cliente</option>
              {displayClientOptions}
            </select>
          </div>
        </div>

        <div className="values">
          <div className="value-card">
            <RecyclingOutlinedIcon />
            <h3>Total retirado: {stats["total_kg_collected"]} [Kg]</h3>
          </div>
          <div className="value-card">
            <LocalShippingOutlinedIcon />
            <h3>Total de retiros: {stats["total_service_schedules"]}</h3>
          </div>
        </div>

        <div className="materials-and-piechart">
          <div className="materials-detail">
            {materialsChunks.map((row, rowIndex) => (
              <div key={rowIndex} className="material-row">
                {row.map((material, index) => (
                  <div
                    key={material["name"]}
                    className="material"
                    style={{ backgroundColor: pastelColors[rowIndex * 3 + index] }}
                  >
                    <div className="material-text">{material["name"]}</div>
                    <div className="value">{material["kg_collected"]} [Kg]</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="piechart-container">
            <Chart
              chartType="PieChart"
              data={statsData}
              options={options}
              width={"400px"}
              height={"200px"}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
