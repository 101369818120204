import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import '../../Estilos/PostForm.css'
import logo from '../../img/logo-rectangle.png'

export default function Register({ token, setToken }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (password === passwordConfirm) {
      const config = {
        method: "POST",
        url: process.env.REACT_APP_DOMAIN + "/register",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        data: {
          "username": username,
          "password": password
        }
      };

      try {
        const response = await axios(config)
        const token = response.data.token;
        localStorage.setItem('token', token);
        setToken(token);

        navigate('/not-authorized');
      } catch (error) {
        console.log(error);
        alert('¡ERROR! ¡No se pudo crear tu cuenta!');
      };
    } else {
      setError('¡Las contraseñas deben ser iguales!')
    }
  }

  useEffect(() => {
    setError(null);
  }, [username, password, passwordConfirm]);

  return (
    <div className={`post-form ${error ? 'error' : ''}`}
      style={{
        animation: 'appear 1s 1'
      }}>
      <div className='logo'>
        <a href='/'>
          <img
            src={logo}
            style={{
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
              borderRadius: '20px',
              height: '7vw',
              zIndex: 1,
              display: 'inline-block',
              marginTop: '3%',
            }}
            id='logo-img'
            alt='Logo de Kyklos'
          />
        </a>
      </div>
      <div className='form-container'>
        <div className='form-title'>
          <h1 className='title'>Crear cuenta</h1>
        </div>

        <hr style={{ marginBottom: '5%' }}></hr>

        <div className='form-items'>
          <div className='form-group'>
            <label htmlFor='username'>Usuario</label>
            <input type='text' id='username' name='username' onChange={e => setUsername(e.target.value)} />
          </div>

          <div className='form-group'>
            <label htmlFor='password'>Contraseña</label>
            <input
              className={error ? 'error-input' : ''}
              type='password' id='password' name='password' onChange={e => setPassword(e.target.value)} />
          </div>

          <div className='form-group'>
            <label htmlFor='confirm-password'>Confirma tu contraseña</label>
            <input
              className={error ? 'error-input' : ''}
              type='password' id='confirm-password' name='confirm-password' onChange={e => setPasswordConfirm(e.target.value)} />
          </div>

          {error && <p
            className='error-message'
            style={{
              fontFamily: 'Dosis',
              animation: 'appear 0.5s 1',
            }}>{error}</p>}
        </div>

        <div className='option-buttons'>
          <button type='submit' className='button' id='register' onClick={handleSignUp}>Registrarse</button>
          <Link to={'/login'}>
            <p className='button-label'>¿Ya tienes una cuenta?</p>
          </Link>
        </div>
      </div>
    </div >
  )
}