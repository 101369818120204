import { useEffect, useState } from "react";
import generatePDF from "../Helpers/reportGenerator"
import apiClient from "../Services/apiClient";
import { meses } from "../Helpers/Stats";

export default function Report() {
  const [clients, setClients] = useState([]);
  const [clientID, setClientID] = useState(0);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  let currentYear = new Date().getFullYear();

  async function getClients() {
    try {
      const response = await apiClient.get("/clients");
      setClients(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function getClientInfo() {
    try {
      const response = await apiClient.get(`/clients/${clientID}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function getStatisticsPDF() {
    try {
      const response = await apiClient.get(
        `/statistics?client=${clientID}&year=${year}&month=${month}`
      );
      const genResp = await apiClient.get(
        `/statistics?client=0&year=${year}&month=${month}`
      );
      let clientInfo = await getClientInfo();
      generatePDF(clientInfo, response.data, genResp.data, year, month);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        //alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        alert(error.response.data.errors)
      }
      return false;
    }
  }

  const displayClientOptions = clients.map((client) => {
    return (
      <option key={client.id} value={client.id}>{client.name}</option>
    )
  })

  const displayYearOptions = Array.from(
    { length: currentYear - 2015 + 1 },
    (_, index) => {
      const yearValue = currentYear - index;
      return (
        <option key={yearValue} value={yearValue.toString()}>
          {yearValue}
        </option>
      );
    }
  );

  const displayMonthOptions = meses.map((mes) => (
    <option key={mes.label} value={mes.value}>{mes.label}</option>
  ));

  useEffect(() => {
    getClients();
  }, [])

  return (
    <div className='generate-report'>
      <h2>Generar reporte para cliente</h2>

      <hr></hr>

      <div className="select-style">
        <select name="clients" id="clients" onChange={(e) => { setClientID(e.target.value) }}>
          <option value="0">Seleccione un cliente</option>
          {displayClientOptions}
        </select>
      </div>
      <div className="select-style" id="years-select">
        <select
          name="years"
          id="years"
          onChange={(e) => {
            setYear(e.target.value);
          }}
        >
          <option value="0">Seleccione un año</option>
          {displayYearOptions}
        </select>
      </div>

      <div className="select-style" id="months-select">
        <select name="months" id="months" onChange={(e) => { setMonth(e.target.value) }}>
          <option value="0">Seleccione un mes</option>
          {displayMonthOptions}
        </select>
      </div>

      <button className='button' id='confirm' onClick={getStatisticsPDF}>Generar PDF</button>
    </div>
  )
}
