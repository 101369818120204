import React, { useState, useEffect } from "react";
import "../../Estilos/Bolsa.css";
import { getColorPastelById } from "../Helpers/Formulas";
import CloseIcon from "@mui/icons-material/Close";
import DisplayRoute from "./DisplayRoute";
import "../../Estilos/PlanSemanal.css";
import DeleteIcon from "@mui//icons-material/Delete";
import apiClient from "../Services/apiClient";

function Ruta(props) {
  const colorPastel = getColorPastelById(props.id);
  const [displayOpen, setDisplayOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (displayOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [displayOpen]);

  const handleClick = () => {
    setDisplayOpen(true);
  };

  const handleClosing = () => {
    setIsClosing(true);
    setTimeout(() => {
      setDisplayOpen(false);
      setIsClosing(false);
    }, 300); // Tiempo de espera para que se complete la animación (ajusta según tus necesidades)
  };

  async function deleteRoute(event) {
    event.stopPropagation(); // Evitar propagación del evento al elemento padre

    if (
      window.confirm(
        `¿Está seguro que desea eliminar la ruta ${props.nombre_ruta}?`
      )
    ) {
      try {
        await apiClient.delete(`/routes/${props.id}?recursive=true`);
        console.log("Ruta eliminada correctamente");
        window.location.reload();
      } catch (error) {
        console.log(error);
        console.log(error.response.data.errors);
        if (error.response && error.response.status === 401) {
          alert("Token Inválido. No puedes realizar esta acción.");
        } else {
          alert(
            "Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente."
          );
        }
        return false;
      }
    }
  }

  return (
    <div className="recuadro" onClick={handleClick}>
      <h4
        style={{
          fontFamily: "Dosis",
          backgroundColor: colorPastel,
          borderRadius: "20px",
          margin: "10px 10px 5px 10px",
        }}
      >
        {props.nombre_ruta}
      </h4>

      <div className="cell">
        {props.direcciones.slice(0, 3).map((direccion, index) => (
          <p
            style={{
              margin: "0",
              fontSize: "13px",
            }}
            key={index}
          >
            {direccion}
          </p>
        ))}

        {props.direcciones.length > 3 && (
          <p
            style={{
              margin: "0",
              fontSize: "13px",
            }}
          >
            . . .
          </p>
        )}
      </div>

      <DeleteIcon
        className="config-button"
        id="delete-route"
        onClick={deleteRoute}
      ></DeleteIcon>

      {displayOpen && (
        <div className={`modal-overlay ${isClosing ? "fade-out" : "fade-in"}`}>
          <div
            className={`modal-content ${isClosing ? "slide-down" : "slide-up"}`}
            id="modal-content-route"
          >
            <button className="close-button" onClick={handleClosing}>
              <CloseIcon className="close-icon" />
            </button>
            <DisplayRoute requestID={props.id} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Ruta;