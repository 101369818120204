import React from "react";
import '../../Estilos/Bolsas.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import apiClient from "../Services/apiClient";

function Solicitud(props) {
  const requestID = props.id;
  let estadoId = "";
  switch (props.estado) {
    case "Pendiente":
      estadoId = "pendiente";
      break;
    case "Agendada":
      estadoId = "en-curso";
      break;
    case "Completada":
      estadoId = "completada";
      break;
    default:
      estadoId = "desconocido";
  }

  async function deleteRequest() {
    if (window.confirm('¿Está seguro que desea eliminar esta solicitud?')) {
      try {
        await apiClient.delete(`/service_schedules/${requestID}?recursive=true`);
        console.log("Solicitud eliminada correctamente");
        window.location.reload();
      } catch (error) {
        console.log(error)
        console.log(error.response.data.errors)
        if (error.response && error.response.status === 401) {
          alert("Token Inválido. No puedes realizar esta acción.");
        } else {
          alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        }
        return false;
      }
    }
  }


  return (
    <div className="row">
      <div className="cell">{props.fecha_ingreso}</div>
      <div className="cell">{props.sucursal}</div>
      <div className="cell">{props.tipo}</div>
      <div className="cell" id={`${estadoId}`}>{props.estado}</div>
      <div className="cell">
        <Link to={`/solicitudes/${props.id}/`}>
          <VisibilityIcon className="config-button" sx={{ mr: 2 }} ></VisibilityIcon>
        </Link>
        <DeleteIcon className="config-button" onClick={deleteRequest}></DeleteIcon>
      </div>
    </div >

  );
}

export default Solicitud;

