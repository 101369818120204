import React, { useEffect, useState } from "react";
import apiClient from "../Services/apiClient";
import { Link, useParams } from "react-router-dom";
import '../../Estilos/PostForm.css';
import '../../Estilos/solicitudes.css'
import '../../App.css'



export default function Display() {
  const requestID = useParams().id;
  const [location, setLocation] = useState('');
  const [identityCode, setIdentityCode] = useState('');
  const [campaign, setCampaign] = useState('');
  const [route, setRoute] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [eta, setETA] = useState('N/A');
  const [expected, setExpected] = useState([]);

  async function getRequestInfo() {
    try {
      const response = await apiClient.get(`/service_schedules/${requestID}?all=true`);
      setLocation(response.data.location);
      setIdentityCode(response.data.location.client.RBD || response.data.location.client.RUT || '0000');
      setCampaign(response.data.campaign.name);
      setRoute(response.data.route);
      setServiceType(response.data.service_type);
      if (response.data.estimated_time_minutes !== null) {
        setETA(response.data.estimated_time_minutes + " minutos");
      }
      console.log(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  useEffect(() => {
    getRequestInfo();
  }, []);

  return (
    <div className="post-form">
      <div className="form-container">
        <div className="form-title">
          <h1 className="title">Solicitud de recolección con ID {requestID}</h1>
        </div>

        <hr></hr>
        <h3>Campaña: {campaign}</h3>
        {
          location.client ?
            (
              <div className="display-elements">
                <div className="location-elements">
                  <div className="group">
                    <label>Cliente:</label>
                    <p>{location.client.name}</p>
                  </div>
                  <div className="group">
                    <label>RUT / XXXX:</label>
                    <p>{identityCode}</p>
                  </div>
                  <div className="group">
                    <label>Destino:</label>
                    <p>{location.address}</p>
                  </div>
                  <div className="group">
                    <label>Responsable:</label>
                    <p>{location.contact.name}</p>
                  </div>
                  <div className="group">
                    <label>Teléfono:</label>
                    <p>{location.contact.phone}</p>
                  </div>
                </div>
                <div className="aditional-elements">
                  <div className="service-elements">
                    <div className="group">
                      <label>Servicio:</label>
                      <p>{serviceType.name}</p>
                    </div>
                  </div>
                  <div className="time-elements">
                    <div className="group">
                      <label>Tiempo estimado:</label>
                      <p>{eta}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
        }

        <div className="option-buttons">
          <Link className="button" id="cancel" to={'/solicitud-recolecciones'}>Volver</Link>
        </div>
      </div>
    </div >
  )
}