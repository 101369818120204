import React from "react";
import { Link } from "react-router-dom";
import '../../Estilos/Bolsa.css';
import VisibilityIcon from '@mui/icons-material/Visibility';

function Recoleccion(props) {
  return (
    <div className="row">
      <div className="cell">{props.fecha}</div>
      <div className="cell">{props.sucursal}</div>
      <div className="cell">{props.campaña}</div>
      <div className="cell">
        <Link to={`./${props.id}/bolsas`} className="icon-link"><VisibilityIcon className="config-button"></VisibilityIcon></Link>
      </div>
    </div>
  );
}

export default Recoleccion;