import React, { useState, useEffect } from "react";
import apiClient from "../Services/apiClient";
import { Chart } from "react-google-charts";
import '../../Estilos/Estadisticas/estadisticas.css';
import { orderStats, meses, pastelColors, chunkArray } from "../Helpers/Stats";
import RecyclingOutlinedIcon from '@mui/icons-material/RecyclingOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

export default function RecuperacionMensual() {
  const [clients, setClients] = useState([]);
  const [clientID, setClientID] = useState(0);
  const [stats, setStats] = useState();
  const [isLoading, setLoading] = useState(true);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);

  const options = {
    title: "Peso por Material",
    is3D: true,
    backgroundColor: "transparent",
    chartArea: { 'width': '100%', 'height': '100%' },
    colors: pastelColors,
    pieSliceText: "value",
    pieSliceTextStyle: {
      fontName: "Dosis",
      color: "black",
      fontSize: 15,
    },
    legend: {
      position: 'right',
      textStyle: {
        fontName: 'Dosis',
        fontSize: 16,
        color: 'black',
      },
    }
  };

  async function getStatistics() {
    try {
      const response = await apiClient.get(
        `/statistics?client=${clientID}&year=${year}&month=${month}`
      );
      setStats(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        //alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        alert(error.response.data.errors)
      }
      return false;
    }
  }

  useEffect(() => {
    getStatistics();
  }, [clientID, year, month]);

  async function fetchClients() {
    try {
      const response = await apiClient.get("/clients/");
      setClients(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  const displayClientOptions = clients.map((client) => (
    <option key={client.id} value={client.id}>
      {client.name}
    </option>
  ));

  useEffect(() => {
    fetchClients();
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  let orderedStats = orderStats(stats && stats.materials);
  let orderedMaterials = orderedStats[0];
  let statsData = orderedStats[1];

  const materialsChunks = chunkArray(orderedMaterials, 3);

  // Obtener el año actual
  const currentYear = new Date().getFullYear();

  // Generar opciones de año desde 2015 hasta el año actual
  const displayYearOptions = Array.from(
    { length: currentYear - 2015 + 1 },
    (_, index) => {
      const yearValue = currentYear - index;
      return (
        <option key={yearValue} value={yearValue.toString()}>
          {yearValue}
        </option>
      );
    }
  );

  const displayMonthOptions = meses.map((mes) => (
    <option key={mes.label} value={mes.value}>{mes.label}</option>
  ));

  return (
    <div className="stats-container">
      <h2>Recuperación Mensual Total [Kg]</h2>
      <hr />

      <div className="elements">
        <div className="select-container">
          <div className="select-style" id="clients-select">
            <select
              name="clients"
              id="clients"
              onChange={(e) => {
                setClientID(e.target.value);
              }}
            >
              <option value="0">Seleccione un cliente</option>
              {displayClientOptions}
            </select>
          </div>

          <div className="select-style" id="years-select">
            <select
              name="years"
              id="years"
              onChange={(e) => {
                setYear(e.target.value);
              }}
            >
              <option value="0">Seleccione un año</option>
              {displayYearOptions}
            </select>
          </div>

          <div className="select-style" id="months-select">
            <select name="months" id="months" onChange={(e) => { setMonth(e.target.value) }}>
              <option value="0">Seleccione un mes</option>
              {displayMonthOptions}
            </select>
          </div>
        </div>

        <div className="values">
          <div className="value-card">
            <RecyclingOutlinedIcon />
            <h3>Total retirado: {stats && stats.total_kg_collected} [Kg]</h3>
          </div>
          <div className="value-card">
            <LocalShippingOutlinedIcon />
            <h3>Total de retiros: {stats && stats.total_service_schedules}</h3>
          </div>
        </div>

        <div className="materials-and-piechart">
          <div className="materials-detail">
            {materialsChunks.map((row, rowIndex) => (
              <div key={rowIndex} className="material-row">
                {row.map((material, index) => (
                  <div
                    key={material["name"]}
                    className="material"
                    style={{ backgroundColor: pastelColors[rowIndex * 3 + index] }}
                  >
                    <div className="material-text">{material.name}</div>
                    <div className="value">{material.kg_collected} [Kg]</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="piechart-container">
            <Chart
              chartType="PieChart"
              data={statsData}
              options={options}
              width={"400px"}
              height={"200px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}