import RecuperacionAcumulada from "./RecuperacionAcumulada"
import RecuperacionMensual from "./RecuperacionMensual"
import FlujoMensual from "./FlujoMensual"
import Report from "../Report/Report"

export default function Estadisticas() {
  return (
    <div className="stats-sections">
      <div className="recuperaciones">
        {<RecuperacionAcumulada />}
        {<Report />}
      </div>
      <div className="flujos">
        {<FlujoMensual />}
        {<RecuperacionMensual />}
      </div>
    </div>
  )
}