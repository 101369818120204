import { useEffect, useState } from 'react';
import '../../Estilos/Admin.css'
import apiClient from '../Services/apiClient';
import { AccessTime } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';

export default function Users() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  async function getUsers() {
    try {
      const response = await apiClient.get("/users?all=true");
      setUsers(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function getRoles() {
    try {
      const response = await apiClient.get("/roles");
      setRoles(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  useEffect(() => {
    getUsers();
    getRoles();
  }, [])

  const pendingUsers = users.filter((user) => {
    if (user.roles.length == 0) {
      return true;
    }
  })

  const displayRolesOptions = roles.map((role) => {
    return (
      <option key={role.id} value={role.id}>{role.name}</option>
    )
  })

  async function changeRole(id) {

    var select = document.getElementById(`${id}`).value;

    let data = {
      "data": {
        "roles": [
          parseInt(select)
        ]
      }
    }

    try {
      const response = await apiClient.patch(`users/${id}/roles`, data);
      window.location.reload(true);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
    }
  }

  return (
    <div className='campaign-options'>

      <h1 className='title' style={{ marginTop: '5vh', }}>Usuarios pendientes</h1>
      <hr></hr>

      <div className='campaigns-display'>
        {pendingUsers.map((user) => {
          return (
            <div className="row row-admin" key={user.id}>
              <div className="cell">{user.username}</div>
              <div className="cell">
                <div className="select-style">
                  <select name="clients" id={user.id}>
                    <option value="0">Seleccione un rol</option>
                    {displayRolesOptions}
                  </select>
                </div>
              </div>
              <div className='cell'>
                <CheckIcon className="config-button" onClick={() => changeRole(user.id)}></CheckIcon>
              </div>
            </div >
          )
        })}
      </div>
    </div>
  )
}