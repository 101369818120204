import React from 'react';
import Disposiciones from './Disposiciones'
import { Link } from 'react-router-dom';
import '../../Estilos/Bolsas.css'
import '../../App.css'

function DisposicionesView(props) {

  return (
    <div className='body'>

      <div className="titulo">
        <h1 className="texto-titulo">Disposiciones</h1>
      </div>

      <div className='agregar-bolsa'>
        <Link to="/new-disposicion">
          <button className='boton-agregar'>Ingresar nueva disposición</button>
        </Link>
      </div>

      <div className='container-view'>
        <h1 className='titulo-container'>Disposiciones registradas</h1>

        <hr></hr>

        <div className="bolsas-container">
          <Disposiciones />
        </div>
      </div>

    </div>
  );
}

export default DisposicionesView;