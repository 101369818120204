import React from 'react';
import '../App.css';
import '../Estilos/Animations.css'
import logo from '../img/kyklos-logo.png';
import { Link } from 'react-router-dom';
import isUserAuthorized from './Helpers/userUtils';
import jwt from 'jwt-decode';


function Navbar({ token, setToken }) {

  function handleLogOut() {
    setToken(null);
    localStorage.clear();
  }


  return (
    <div className='navbar'
      style={{
        animation: "navbar-animation 1s 1"
      }}>
      <div className='logo'>
        <a href='/'>
          <img src={logo} alt="Logo de Kyklos" />
        </a>
      </div>
      <div className='options'>
        {isUserAuthorized(jwt(token).roles) && (
          <>
          <Link
          style={{
            animation: "appear 1s 1"
          }}
          to={'/admin'} className='item'>Admin</Link>
          <Link
            style={{
              animation: "appear 1s 1"
            }}
            to={'/solicitud-recolecciones'} className='item'>Solicitudes</Link>
          <Link
            style={{
              animation: "appear 1s 1"
            }}
            to={'/plan-semanal'} className='item'>Planificación semanal</Link>
          <Link
            style={{
              animation: "appear 1s 1"
            }}
            to={'/recolecciones'} className='item'>Segregación</Link>
          <Link
            style={{
              animation: "appear 1s 1"
            }}
            to={'/disposiciones'} className='item'>Disposición</Link>
          <Link
            style={{
              animation: "appear 1s 1"
            }}
            to={'/estadisticas'} className='item'>Estadísticas</Link>
          </>
        )}
        <Link
          style={{
            animation: "appear 1s 1"
          }}
          to={'/'} className='item' onClick={handleLogOut}>Cerrar Sesión</Link>
      </div>
    </div>
  )
}

export default Navbar;