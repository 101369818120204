import React from 'react';
import Recolecciones from './Recolecciones.jsx'
import { Link } from 'react-router-dom';
import '../../Estilos/Bolsas.css'

function RecoleccionesView(props) {
  return (
    <div className='body'>

      <p className='instructions'>
        Aquí podrás segregar las bolsas pendientes. Elige una recolección, selecciona "ver
        bolsas" y por cada una realiza la acción "segregar" por subtipos
      </p>

      <div className="agregar-bolsa">
        <Link to="/recolecciones/new">
          <button className='boton-agregar'> Agregar recolección </button>
        </Link>
      </div>

      <div className='container-view'>
        <h1 className='titulo-container'>Recolecciones ingresadas</h1>


        <hr></hr>

        <div className='bolsas-container'>
          <Recolecciones />
        </div>
      </div>
    </div >
  );
}

export default RecoleccionesView;