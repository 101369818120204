import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import '../../App.css'
import '../../Estilos/PostForm.css'
import logo from '../../img/logo-rectangle.png'



export default function Login({ token, setToken }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleLogin = async () => {
    const config = {
      method: "POST",
      url: process.env.REACT_APP_DOMAIN + "/login",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      data: {
        "username": username,
        "password": password
      }
    };

    try {
      const response = await axios(config);
      const token = response.data.token;
      setToken(token);
      localStorage.setItem('token', token);

      navigate('/');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('¡Estas credenciales son inválidas!');
      } else {
        console.log(error);
      }
    };
  };

  useEffect(() => {
    setError(null);
  }, [username, password]);

  return (
    <div className={`post-form ${error ? 'error' : ''}`}
      style={{
        animation: 'appear 1s 1'
      }}>
      <div className='logo'>
        <a href='/'>
          <img
            src={logo}
            style={{
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
              borderRadius: '20px',
              height: '7vw',
              zIndex: 1,
              display: 'inline-block',
              marginTop: '3%',
            }}
            id='logo-img'
            alt='Logo de Kyklos'
          />
        </a>
      </div>
      <div className='form-container'>
        <div className='form-title'>
          <h1 className='title'>Iniciar sesión</h1>
        </div>

        <hr style={{ marginBottom: '5%' }} />

        <div className='form-items'>
          <div className='form-group'>
            <label htmlFor='username'>Usuario</label>
            <input
              type='text'
              id='username'
              name='username'
              className={error ? 'error-input' : ''}
              onChange={e => setUsername(e.target.value)}
            />
          </div>

          <div className='form-group'>
            <label htmlFor='password'>Contraseña</label>
            <input
              type='password'
              id='password'
              name='password'
              className={error ? 'error-input' : ''}
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          {error && <p
            className='error-message'
            style={{
              fontFamily: 'Dosis',
              animation: 'appear 0.5s 1',
            }}>{error}</p>}
        </div>

        <div className='option-buttons'>
          <button type='submit' className='button' id='login' onClick={handleLogin}>
            Iniciar sesión
          </button>
          <Link
            to='/signup'>
            <p
              className='button-label'
            >¿Aún no tienes una cuenta?</p>
          </Link>
        </div>
      </div >
    </div >
  );
}