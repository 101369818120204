import '../../Estilos/Admin.css'
import Campaigns from './Campaigns';
import Users from './Users';
import Drivers from './Drivers';


export default function Admin() {
  return (
    <>
      <div className='admin-options'>
        < Drivers />
      </div>
      <div className='admin-options'>
        < Campaigns />
        < Users />
      </div>
    </>
  )
}
