import React, { useEffect, useState } from "react";
import apiClient from "../Services/apiClient";
import obtenerFechaEscrita from "../Helpers/Formulas";
import { getTipoServicioById } from "../Helpers/Formulas";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BoyIcon from '@mui/icons-material/Boy';
import { Place } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import Repeat from "./Repeat";


export default function DisplayRoute({ requestID }) {
  const handleClick = () => {
    setDisplayOpen(true);
    console.log("displayOpen", displayOpen);
  };

  const handleClosing = () => {
    setIsClosing(true);
    setTimeout(() => {
      setDisplayOpen(false);
      setIsClosing(false);
    }, 300);
  };
  const [routeName, setRouteName] = useState('');
  const [scheduledDate, setScheduledDate] = useState('');
  const [vehicle, setVehicle] = useState('');
  const [stops, setStops] = useState([]);
  const [crew, setCrew] = useState([]);
  const [displayOpen, setDisplayOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);


  async function fetchRouteInfo() {
    try {
      const response = await apiClient.get(`/routes/${requestID}?all=true`);
      const requestData = response.data;
      setRouteName(requestData.name);
      setScheduledDate(requestData.scheduled_at)
      setVehicle(requestData.vehicle);
      setStops(requestData.service_schedules);
      setCrew(requestData.crew.drivers);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente:).");
      }
      return false;
    }
  }

  useEffect(() => {
    fetchRouteInfo();
  });

  const formattedDate = obtenerFechaEscrita(scheduledDate)

  return (
    <div className="post-form">
      <div className="form-title">
        <h1 className="title" style={{ margin: 0 }}>{routeName}</h1>
        <div className="info-container">
          <div className="info-item" style={{ backgroundColor: 'pink', fontSize: '20px' }}>
            {formattedDate}
          </div>
        </div>
        <div className='new-route-button' onClick={() => handleClick()}>
          Duplicar
        </div>
      </div>

      <hr></hr>

      <div className="route-container">
        <h3>Equipo</h3>
        <div className="crew-members">
          <div className="crew-member">
            <DirectionsCarIcon className="icon" />
            <p>{vehicle.license_plate}</p>
          </div>
          {crew.map(crewMember => (
            <div className="crew-member" key={crewMember.rut}>
              <BoyIcon className="icon" />
              <p>{`${crewMember.name} - ${crewMember.rut}`}</p>
            </div>
          ))}
        </div>
      </div>


      <div className="paradas-container">
        <h3>Paradas</h3>
        {stops.map(stop => (
          <div className="parada" key={stop.id}>
            <div className="cliente-lugar">
              <div className="cliente">
                <p><b>Cliente:</b> {stop.location.client.name}</p>
              </div>
              <div className="lugar">
                <Place className="place-icon" />
                <p>{`${stop.location.address}, ${stop.location.sector.name}`}</p>
                <div style={{ flex: 1 }}>
                  <div className="service-type" style={{ backgroundColor: getTipoServicioById(stop.service_type_id)[0] }}>
                    {getTipoServicioById(stop.service_type_id)[1]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {displayOpen && (
        <div className={`modal-overlay ${isClosing ? "fade-out" : "fade-in"}`}>
          <div className={`modal-content ${isClosing ? "slide-down" : "slide-up"}`} id='modal-content-new-route'>
            <button className="close-button" onClick={handleClosing}>
              <CloseIcon className="close-icon" />
            </button>
            <Repeat routeId={requestID} />
          </div>
        </div>
      )}
    </div>
  );
}
