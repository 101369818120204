import { useState } from 'react';
import '../../Estilos/Admin.css'
import apiClient from '../Services/apiClient';


export default function Drivers() {
  const [rut, setRut] = useState("");
  const [nombre, setNombre] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            'data': {
                'rut': rut,
                'name': nombre,
            }
        };

        try {
            const response = await apiClient.post('/drivers', data);
            if (response.status === 200) {
                alert('¡El chofer se ha creado exitosamente!');
                setRut("");
                setNombre("");
            }
        } catch (error) {
            alert(error.response.data.errors);
        }
    };


  return (
    <div className='campaign-options'>
    <h1 className='title' style={{ marginTop: '5vh', }}>Crear Chofer Nuevo</h1>
    <hr></hr>
    <div className='form'>
        <form onSubmit={handleSubmit}>
            <div className='form-group'>
                <label>Rut</label>
                <input className='select-style-new-route' type='text' value={rut} onChange={e => setRut(e.target.value)} required />
            </div>
            <div className='form-group'>
                <label>Nombre</label>
                <input className='select-style-new-route' type='text' value={nombre} onChange={(e) => setNombre(e.target.value)} required />
            </div>
            <button className="button" id="confirm" type='submit'>Confirmar</button>
        </form>
    </div>
    </div>
  )
}
