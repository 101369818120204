import React from "react";
import { useState, useEffect } from "react";
import '../../Estilos/PostForm.css';
import apiClient from "../Services/apiClient";


function InputResTypes(props) {

    const [tipos, setTipos] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await apiClient.get("/residue_types");
            setTipos(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              alert("Token Inválido. No puedes realizar esta acción.");
            } else {
              alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
            }
            return false;
          }
        };
    
        fetchData();
      }, []);

    return (
        <div className="select-container">
            <input list="residue-types" className="residue-type input" />
            <datalist id="residue-types">
                {tipos.map((option) => (
                    <option value={option["name"]}></option>
                ))}
            </datalist>
        </div>
    );
}

export default InputResTypes;