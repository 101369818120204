import jsPDF from "jspdf";
import "jspdf-autotable";

let months = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octube",
  11: "Noviembre",
  12: "Diciembre"
}

function writeTitle(doc) {
  doc.setFontSize(15);
  doc.text("CERTIFICADO DE TRAZABILIDAD Y DESTINO SUSTENTABLE DE RESIDUOS", 14, 15);
}

function writeClient(doc, client) {
  let text = client.name
  let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
  let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  doc.setFontSize(19);
  doc.setFont("helvetica", "bold");
  doc.text(text, pageWidth / 2, 30, {align: 'center'})
}

function writeDescription(doc) {
  let description = `El presente documento certifica que Asesorías y Servicios Kyklos SPA., RUT: 76.247.523-5,
empresa de gestión integral e inclusiva de residuos, ha gestionado los residuos de Uno Salud
bajo las Resoluciones de SEREMI de Salud R.M. N° 8892 y 8910, asegurando una
disposición sustentable.`
  let splitDescription = doc.splitTextToSize(description, 700);
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(splitDescription, 14, 40);
}

function writeResiduesInfo(doc, year, month) {
  doc.setFontSize(17);
  doc.setFont("helvetica", "bold");
  doc.text("RESIDUOS GESTIONADOS", 14, 70);
  doc.setFontSize(12);
  doc.text(`${month} ${year}`, 14, 75);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.text(`La empresa acredita que al 100% del material se le ha entregado un destino final según su tratamiento.`, 14, 80);
}

function writeResiduesDetails(doc) {
  doc.setFontSize(17);
  doc.setFont("helvetica", "bold");
  doc.text("DETALLES DE RETIROS DEL MES", 14, 100);
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text("En kilogramos", 14, 105);
}

const generatePDF = (client, stats, genStats, year, month) => {
  const doc = new jsPDF();
  writeTitle(doc);
  writeClient(doc, client);
  writeDescription(doc);
  writeResiduesInfo(doc, year, months[month]);
  writeResiduesDetails(doc);


  let materials = Object.values(stats.materials);
  const tableColumn = ["Material", "KG recoletados", "KG vendidos (Total)"];
  const tableRows = [];

  materials.forEach((material, key) => {
    key += 1;
    const materialData = [
      material.name,
      material.kg_collected,
      genStats.materials[key].kg_sold,
    ];
    tableRows.push(materialData);
  });
  tableRows.push(["Total", stats.total_kg_collected, stats.total_kg_sold])

  doc.autoTable(tableColumn, tableRows, { startY: 120 });
  doc.save(`report_${client.name}_${months[month]}_${year}.pdf`);
};

export default generatePDF;
