import React, { useState } from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import apiClient from "../Services/apiClient";
import '../../Estilos/Bolsa.css';


async function BorrarDisp(sale) {
  try {
    const response = await apiClient.delete(`/sales/${sale}?recursive=true`);
    return true;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      alert("Token Inválido. No puedes realizar esta acción.");
    } else {
      alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      console.log(error);
    }
    return false;
  }
}

function Disposicion(props) {
  const [editing, setEditing] = useState(false);
  function handleEditClick() {
    setEditing(true);
  }

  var tipo = "";
  var valor = props.valor
  if (props.valor < 0) {
    tipo = "Desecho";
    valor = 0 - valor;
  }
  else {
    tipo = "Venta";
  }

  return (
    <div className="row">
      <div className="cell">{props.fecha}</div>
      <div className="cell">{tipo}</div>
      <div className="cell">{props.destino}</div>
      <div className="cell">{valor}</div>
      <div className="cell">
        <Link to={`/disposiciones/${props.id}`}>
          <VisibilityIcon className="config-button" sx={{ mr: 2 }} ></VisibilityIcon>
        </Link>
        <Link to={`/disposiciones/${props.id}/edit`}>
          <EditIcon sx={{ mr: 2 }} className="config-button" onClick={handleEditClick}></EditIcon>
        </Link>
        <DeleteIcon className="config-button" onClick={async () => {
          if (window.confirm('¿Está seguro que desea eliminar esta disposición?')) {
            const respuesta = await BorrarDisp(props.id);
            if (respuesta) {
              window.location.reload(true);
            }
            else {
              alert("HUBO UN ERROR");
            }
          }
        }} ></DeleteIcon>
      </div>
    </div >

  );
}


export default Disposicion;