import React, { useEffect, useState } from "react";
import apiClient from "../Services/apiClient";
import { formatDate, getColorPastelById } from "../Helpers/Formulas";
import { getTipoServicioById } from "../Helpers/Formulas";
import DisplayForm from "./DisplayForm";
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

export default function DisplayScheduleService({ requestID }) {
  const [location, setLocation] = useState('');
  const [creationDate, setCreationDate] = useState('');
  const [campaign, setCampaign] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [showForm, setShowForm] = useState(false);

  const colorPastel = getColorPastelById(campaign.id)
  const [colorTipoServicio, textoTipoServicio] = getTipoServicioById(serviceType.id)

  async function fetchRequestInfo() {
    try {
      const response = await apiClient.get(`/service_schedules/${requestID}?all=true`);
      const requestData = response.data;
      setLocation(requestData.location);
      setCampaign(requestData.campaign);
      setCreationDate(requestData.created_at);
      setServiceType(requestData.service_type);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }


  const toggleForm = () => {
    setShowForm((prevShowForm) => !prevShowForm);
  };

  useEffect(() => {
    fetchRequestInfo();
  });

  const formattedDate = formatDate(creationDate)

  return (
    <div className="post-form">
      <div className="form-title">
        <h1 className="title">Solicitud de recolección con ID {requestID}</h1>
        <div className="info-container">
          <div className="info-item" style={{ backgroundColor: colorTipoServicio }}>
            {textoTipoServicio}
          </div>
          <div className="info-item" style={{ backgroundColor: colorPastel }}>
            {campaign.name}
          </div>
        </div>
      </div>

      <hr></hr>
      <div
        className='button'
        id={`${showForm ? "later" : "confirm"}`}
        onClick={toggleForm}
      >
        {showForm ? "Mejor más tarde" : "Asignar ruta, duración y horario"}
      </div>

      {showForm && (
        <DisplayForm requestID={requestID}></DisplayForm>
      )}

      {location.client ? (
        <div className='info-display'>
          <div className="flex-container">
            <div className='client-and-created-at'>
              <div className="client-box">
                <h3>Cliente</h3>
                <p>{location.client.name}</p>
                <p>
                  {location.address}, {location.sector.name}
                </p>
              </div>
              <div className='created-at-box'>
                <p><b>Fecha ingreso:</b> {formattedDate}</p>
              </div>
            </div>

            <div className="contact-box">
              <h3>Contacto</h3>
              <div className="contact-info">
                <PersonIcon className="contact-icon" />
                <p className="contact-data">{location.contact.name}</p>
              </div>
              <div className="contact-info">
                <PhoneIphoneIcon className="contact-icon" />
                <p className="contact-data">{location.contact.phone}</p>
              </div>
              <div className="contact-info">
                <AlternateEmailIcon className="contact-icon" />
                <p className="contact-data">{location.contact.email}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
