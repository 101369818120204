import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../Estilos/PostForm.css";
import "../../Estilos/solicitudes.css";
import "../../App.css";
import apiClient from "../Services/apiClient";

export default function AddLocation() {
  const [clientID, setClientID] = useState(0);
  const [clients, setClients] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contactID, setContactID] = useState(0);
  const [sectors, setSectors] = useState([]);
  const [sectorID, setSectorId] = useState(0);
  const [address, setAddress] = useState("");

  async function createNewLocation() {
    const data = {
      data: {
        client_id: parseInt(clientID),
        contact_id: parseInt(contactID),
        sector_id: parseInt(sectorID),
        address: address,
        georeference_lat: null,
        georeference_lon: null,
      },
    };

    try {
      await apiClient.post("/locations", data);
      console.log("Location created successfully");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function fetchClients() {
    try {
      const response = await apiClient.get("/clients");
      setClients(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function fetchContacts() {
    try {
      const response = await apiClient.get("/contacts");
      setContacts(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function fetchSectors() {
    try {
      const response = await apiClient.get("/sectors");
      setSectors(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  useEffect(() => {
    fetchClients();
    fetchContacts();
    fetchSectors();
  }, []);

  const displayClientOptions = clients.map((client) => (
    <option key={client.id} value={client.id}>
      {client.name}
    </option>
  ));

  const contactOptions = contacts.map((contact) => (
    <option key={contact.id} value={contact.id}>
      {contact.name}
    </option>
  ));

  const sectorOptions = sectors.map((sector) => (
    <option key={sector.id} value={sector.id}>
      {sector.name}
    </option>
  ));

  return (
    <div className="post-form">
      <div className="form-container">
        <div className="form-title">
          <h1 className="title">Crear nueva ubicación</h1>
        </div>

        <hr></hr>
        <div className="display-elements">
          <div className="location-elements">
            <div className="group">
              <label>Cliente</label>
              <div className="group">
                <select
                  name="clients"
                  id="clients"
                  onChange={(e) => {
                    setClientID(e.target.value);
                  }}
                >
                  <option value="0">Seleccione un cliente</option>
                  {displayClientOptions}
                </select>
              </div>
            </div>
            <div className="group">
              <label>Contacto</label>
              <div className="group">
                <select
                  name="clients"
                  id="clients"
                  onChange={(e) => {
                    setContactID(e.target.value);
                  }}
                >
                  <option value="0">Seleccione un contacto</option>
                  {contactOptions}
                </select>
              </div>
            </div>
            <div className="group">
              <label>Sector</label>
              <div className="group">
                <select
                  name="clients"
                  id="clients"
                  onChange={(e) => {
                    setSectorId(e.target.value);
                  }}
                >
                  <option value="0">Seleccione un contacto</option>
                  {sectorOptions}
                </select>
              </div>
            </div>
            <div className="group">
              <label>Destino</label>
              <div className="group">
                <input
                  className="custom-input"
                  type="text"
                  id="recoleccionName"
                  name="recoleccionName"
                  placeholder="Dirección"
                  onChange={(e) => setAddress(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>

        <div className="option-buttons">
          <Link className="button" id="cancel" to={"/new-solicitud"}>
            Volver
          </Link>
          <Link
            className="button"
            id="confirm"
            to={"/new-solicitud"}
            onClick={createNewLocation}
          >
            Confirmar
          </Link>
        </div>
      </div>
    </div>
  );
}
