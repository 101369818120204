import React, { useState, useEffect } from "react";
import Disposicion from "./Disposicion";
import apiClient from "../Services/apiClient";

function armarDisp(params) {
  var tipo = "";
  return (
    <Disposicion
      id={params["id"]}
      fecha={params["created_at"].slice(0, 10)}
      destino={params["receiver_client"]["name"]}
      valor={params["price"]}
    />
  );
}

export default function Disposiciones() {
  const [disposiciones, setDisp] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [disposicionesPerPage] = useState(10);
  const [filteredDisposiciones, setFilteredDisposiciones] = useState([]);

  useEffect(() => {
    const fetchDisposiciones = async () => {
      try {
        const response = await apiClient.get("/sales?all=true");
        setDisp(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert("Token Inválido. No puedes realizar esta acción.");
        } else {
          alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        }
        return false;
      }
    };
  
    fetchDisposiciones();
  }, []);  

  useEffect(() => {
    setFilteredDisposiciones(disposiciones);
  }, [disposiciones]);

  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastDisposicion = currentPage * disposicionesPerPage;
  const indexOfFirstDisposicion = indexOfLastDisposicion - disposicionesPerPage;
  const currentDisposiciones = filteredDisposiciones.slice(
    indexOfFirstDisposicion,
    indexOfLastDisposicion
  );

  const handleFilter = (filterValue) => {
    const filteredData = disposiciones.filter((disposicion) => {
      // Aquí puedes aplicar tus criterios de filtro
      return disposicion.fecha.includes(filterValue); // Ejemplo de filtro por fecha
    });
    setFilteredDisposiciones(filteredData);
    setCurrentPage(1); // Reiniciar a la primera página después de aplicar el filtro
  };

  return (
    <div className="table-container">
      <div className="table">
        <div className="row" id="header">
          <div className="header">Fecha</div>
          <div className="header">Tipo</div>
          <div className="header">Receptor</div>
          <div className="header">Valor</div>
          <div className="header">Detalle</div>
        </div>
        {currentDisposiciones.map((disposicion) => armarDisp(disposicion))}
      </div>
      {filteredDisposiciones.length > disposicionesPerPage && (
        <div className="pagination">
          <ul className="pagination-list">
            {Array.from(
              { length: Math.ceil(filteredDisposiciones.length / disposicionesPerPage) },
              (_, index) => (
                <li
                  key={index}
                  className={`pagination-item ${index + 1 === currentPage ? "active" : ""}`}
                  onClick={() => handleChangePage(index + 1)}
                >
                  {index + 1}
                </li>
              )
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
