import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../../Estilos/Admin.css'
import apiClient from '../Services/apiClient';
import Campaign from '../Campaign/Campaign'


function armarCamps(params) {
  return (
    <Campaign
      id={params.id}
      nombre={params.name}
      active={params.active}
    />
  );
}

export default function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);

  async function getCampaigns() {
    try {
      const response = await apiClient.get("/campaigns");
      setCampaigns(response.data);
      console.log(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  useEffect(() => {
    getCampaigns();
  }, [])

  return (
    <div className="campaign-options">
      <div className='option'>
        <Link to={`/new-campaign`}>
          <button className='boton-agregar'>Crear campaña</button>
        </Link>
      </div>
      <div className='campaigns-display'>
        {campaigns.map((camp) => armarCamps(camp))}
      </div>
    </div>
  )
}