import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BolsaDisp from "./BolsaDisposicion";
import apiClient from "../Services/apiClient";

function armarBolsaDisp(params) {
    return (
        <BolsaDisp
            tipo={params["residue_type"]["name"]}
            peso={params["weight_kg"]}
            idsale={params["sale_id"]}
            idbolsa={params["bag_id"]}
        />
    );
}

export default function BolsasDisposiciones() {
    const { id } = useParams();
    const [bolsas, setBolsas] = useState([]);
    useEffect(() => {
        const fetchBolsas = async () => {
          try {
            const response = await apiClient.get(`/sales/${id}?all=true`);
            setBolsas(response.data["sales_bags"]);
        } catch (error) {
            if (error.response && error.response.status === 401) {
              alert("Token Inválido. No puedes realizar esta acción.");
            } else {
              alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
            }
            return false;
          }
        };
    
        fetchBolsas();
      }, [id]);
    return (
        <div className="table-container">
            <div className="table">
                <div className="row" id="header">
                    <div className="header" id="bolsas-row">Tipo</div>
                    <div className="header" id="bolsas-row">Peso [Kg]</div>
                    <div className="header" id="bolsas-row"></div>
                </div>
                {bolsas.map((bolsa_disposicion) => armarBolsaDisp(bolsa_disposicion))}
            </div>
        </div>
    );
};