import React from "react";
import apiClient from "../Services/apiClient";
import '../../Estilos/Admin.css'
import DeleteIcon from '@mui/icons-material/Delete';

async function deleteCampaign(campaignID) {
  if (window.confirm('¿Está seguro que desea eliminar esta campaña?')) {
    try {
      await apiClient.delete(`/campaigns/${campaignID}`);
      console.log("Campaña eliminada correctamente");
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }
}

async function changeCampaignStatus(campaignID, isActive) {
  try {
    const response = await apiClient.patch(`/campaigns/${campaignID}`, {
      data: {
        active: !isActive
      }
    });
    console.log(response);
    return true
  } catch (err) {
    console.log(err);
    return false
  }
}

function Campaign(props) {
  let not_active = "row row-admin";
  if (!props.active) {
    not_active += " not-active";
  }
  return (
    <div className={not_active}>
      <p className="cell" id="camp-row">{props.nombre}</p>
      {(props.active) ? (
        <p className="cell active" id="camp-row">Activa</p>
      ) : (
        <p className="cell not-active" id="camp-row">Inactiva</p>
      )}
      <button className='cell change-status' id="camp-row" onClick={async () => {
        const respuesta = await changeCampaignStatus(props.id, props.active);
        if (respuesta) {
          window.location.reload(true);
        }
        else {
          alert("HUBO UN ERROR");
        }
      }} > {(props.active) ? ("Desactivar") : ("Activar")} </button>
      <div className="cell">
        <DeleteIcon className="config-button" onClick={() => deleteCampaign(props.id)}></DeleteIcon>
      </div>
    </div>
  );
}

export default Campaign;
