import React, { useState } from "react";
import { Link } from "react-router-dom";
import apiClient from "../Services/apiClient";
import '../../Estilos/PostForm.css';
import '../../Estilos/solicitudes.css'
import '../../App.css'



export default function AddClient() {
  const [name, setName] = useState("");
  const [RBD, setRBD] = useState(null);
  const [RUT, setRUT] = useState(null);

  async function createNewLocation() {
    try {
      const response = await apiClient.post("/clients", {
        data: {
          name: name,
          RBD: RBD,
          RUT: RUT,
        },
      });
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  return (
    <div className="post-form">
      <div className="form-container">
        <div className="form-title">
          <h1 className="title">Crear nuevo cliente</h1>
        </div>

        <hr></hr>
        <div className="display-elements">
            <div className="group">
              <label>Cliente</label>
              <input className="custom-input" type="text" id="recoleccionName" name="recoleccionName" placeholder="Nombre Cliente" onChange={(e) => setName(e.target.value)}></input>
            </div>
            <br></br>
            <div className="group">
              <label>RBD</label>
              <input className="custom-input" type="text" id="recoleccionName" name="recoleccionName" placeholder="RBD. Si no posee, dejar en blanco." onChange={(e) => setRBD(e.target.value)}></input>
            </div>
            <div className="group">
              <label>RUT</label>
              <input className="custom-input" type="text" id="recoleccionName" name="recoleccionName" placeholder="RUT. Si no posee, dejar en blanco" onChange={(e) => setRUT(e.target.value)}></input>
            </div>
        </div>

        <div className="option-buttons">
          <Link className="button" id="cancel" to={'/new-solicitud'}>Volver</Link>
          <Link className="button" id="confirm" to={'/new-solicitud'} onClick={createNewLocation}>Confirmar</Link>
        </div>
      </div>
    </div >
  )
}