import { useState, useEffect } from "react";
import apiClient from "../Services/apiClient";
import Ruta from "./Ruta";
import SolicitudPendiente from "./SolicitudPendiente";
import moment from "moment-timezone";
import { filtrarRutas, getDayTitles } from "../Helpers/Formulas";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { Place } from "@mui/icons-material";
import NewRoute from "./NewRoute";

function armarRuta(params) {
    const addresses = params["service_schedules"].map(
        (schedule) => schedule.location.address
    );

    return (
        <Ruta
            key={params["id"]}
            id={params["id"]}
            nombre_ruta={params["name"]}
            dia_agendada={params["scheduled_at"]}
            direcciones={addresses}
        />
    );
}

function armarSolPend(params) {
    const bagCount = params.pickup_bags.length;

    return (
        <SolicitudPendiente
            key={params["id"]}
            id={params["id"]}
            tipo_servicio={params["service_type_id"]}
            cliente={params["location"]["client"]["name"]}
            direccion={params["location"]["address"]}
            num_bolsas={bagCount}
        />
    );
}


function PlanSemanal() {
    const [rutas, setRutas] = useState([]);
    const [solicitudes, setSolicitudes] = useState([]);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [skip, setSkip] = useState(0);
    const [sector, setSector] = useState(null);
    const [sectors, setSectors] = useState([]);
    const [displayOpen, setDisplayOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [routeDay, setRouteDay] = useState(null);
    const [routePageIndexes, setRoutePageIndexes] = useState({});

    useEffect(() => {
        const request1 = apiClient.get("/routes", {
            params: {
                all: true,
                sector: sector,
            },
        });
        const request2 = apiClient.get("/service_schedules", {
            params: {
                all: true,
                status: "not_scheduled",
                limit: 3,
                skip: skip,
                sector: sector,
            },
        });
        const request3 = apiClient.get("/sectors");

        Promise.all([request1, request2, request3])
            .then(([response1, response2, response3]) => {
                setRutas(response1.data);
                setSolicitudes(response2.data);
                setSectors(response3.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        if (displayOpen) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        };
    }, [skip, sector, displayOpen]);

    useEffect(() => {
        const initialRoutePageIndexes = getDayTitles(selectedWeek).reduce((acc, day) => {
            return {
                ...acc,
                [day.day]: 0,
            };
        }, {});
        setRoutePageIndexes(initialRoutePageIndexes);
    }, [selectedWeek]);

    const handleChangeFiltroSemana = (event) => {
        setSelectedWeek(event.target.value);
    };

    const handleChangeSector = (event) => {
        setSector(event.target.value);
    };

    const handleNextPage = () => {
        setSkip(skip + 3);
    };

    const handlePreviousPage = () => {
        if (skip >= 3) {
            setSkip(skip - 3);
        }
    };

    const handleNextRoutePage = (day) => {
        setRoutePageIndexes(prevPageIndexes => ({
            ...prevPageIndexes,
            [day]: prevPageIndexes[day] + 3,
        }));
    };

    const handlePreviousRoutePage = (day) => {
        if (routePageIndexes[day] >= 3) {
            setRoutePageIndexes(prevPageIndexes => ({
                ...prevPageIndexes,
                [day]: prevPageIndexes[day] - 3,
            }));
        }
    };

    const handleClick = (date) => {
        setDisplayOpen(true);
        setRouteDay(date);
    };

    const handleClosing = () => {
        setIsClosing(true);
        setTimeout(() => {
            setDisplayOpen(false);
            setIsClosing(false);
        }, 300);
    };

    return (
        <div className='weekly-plan'>
            <p className='instructions'>
                Aquí podrás ver las recolecciones pendientes para la semana, las que ya
                se realizaron y puedes asignarle una fecha y duración a las pendientes
            </p>

            <div className='filters'>
                <div className='date-filter'>
                    <label className='label' htmlFor='filtroSemana'>
                        Selecciona la semana que quieras revisar:
                    </label>
                    <input className='input' type="week"
                        onChange={handleChangeFiltroSemana}
                        defaultValue={moment().format("YYYY-[W]WW")}
                    />
                </div>

                <div className='place-filter'>
                    <label className='label' htmlFor='sector-select'>
                        Sector:
                    </label>

                    <div className='input-container'>
                        <Place className='icon' />
                        <select className='dropdown-list'
                            id="sector-select"
                            onChange={handleChangeSector}
                            value={sector || '0'}
                        >
                            <option value='0'>Todos</option>
                            {sectors.map((sector) => (
                                <option key={sector.id} value={sector.id}>
                                    {sector.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>


            <div className='days-container'>
                {getDayTitles(selectedWeek).map((day) => (
                    <div
                        className='column'
                        key={day.day}
                        id={`${day.isToday ? 'today' : ''} ${day.day}`}
                        style={{ backgroundColor: day.isToday ? "#Ffcece" : "#cce7ff" }}
                    >
                        <h2>{day.daySpanish.charAt(0).toUpperCase() + day.daySpanish.slice(1)}</h2>
                        <hr />

                        {filtrarRutas(day.date, rutas).slice(routePageIndexes[day.day], routePageIndexes[day.day] + 3).map((ruta) => armarRuta(ruta))}

                        <div className='new-route-button' onClick={() => handleClick(day.date)}>
                            Nueva ruta
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <div style={{ margin: '0 auto 0 0' }}>
                                {routePageIndexes[day.day] > 0 && (
                                    <ArrowBackIosIcon
                                        style={{
                                            cursor: "pointer",
                                            position: 'absolute',
                                            bottom: '2%',
                                            left: '5%'
                                        }}
                                        onClick={() => handlePreviousRoutePage(day.day)}
                                    />
                                )}
                            </div>

                            <div style={{ marginLeft: "auto" }}>
                                {filtrarRutas(day.date, rutas).slice(routePageIndexes[day.day], routePageIndexes[day.day] + 4).length > 3 && (
                                    <ArrowForwardIosIcon
                                        style={{
                                            cursor: "pointer",
                                            position: 'absolute',
                                            bottom: '2%',
                                            right: '1%'
                                        }}
                                        onClick={() => handleNextRoutePage(day.day)}
                                    />
                                )}
                            </div>
                        </div>

                    </div>
                ))}

                <div className='column' id='pending'>
                    <h2>Pendientes</h2>
                    <hr id='pending-hr' />

                    {solicitudes.map((solicitud) => armarSolPend(solicitud))}

                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ margin: '0 auto 0 0' }}>
                            {skip > 0 && (
                                <ArrowBackIosIcon
                                    style={{
                                        cursor: "pointer",
                                        position: 'absolute',
                                        bottom: '2%',
                                        left: '5%'
                                    }}
                                    onClick={handlePreviousPage}
                                />
                            )}
                        </div>

                        <div style={{ marginLeft: "auto" }}>
                            {solicitudes.length === 3 && (
                                <ArrowForwardIosIcon
                                    style={{
                                        cursor: "pointer",
                                        position: 'absolute',
                                        bottom: '2%',
                                        right: '1%'
                                    }}
                                    onClick={handleNextPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {displayOpen && (
                <div className={`modal-overlay ${isClosing ? "fade-out" : "fade-in"}`}>
                    <div className={`modal-content ${isClosing ? "slide-down" : "slide-up"}`} id='modal-content-new-route'>
                        <button className="close-button" onClick={handleClosing}>
                            <CloseIcon className="close-icon" />
                        </button>
                        <NewRoute routeDay={routeDay} />
                    </div>
                </div>
            )}

        </div>
    );
}

export default PlanSemanal;