import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import './Estilos/Bolsa.css';
import { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import isUserAuthorized from './Components/Helpers/userUtils';

import HomeLogOff from './Components/LogOff/Home';
import SegregacionBolsa from './Components/Segregaciones/SegregacionBolsa';
import SegregacionView from './Components/Bolsas/SegregacionBolsa';
import AgregarBolsa from './Components/Bolsas/AgregarBolsa';
import BolsasView from './Components/Bolsas/BolsasView.jsx'
import DisposicionesView from './Components/Disposiciones/DisposicionesView'
import AgregarBolsaDisposicion from './Components/Disposiciones/AgregarBolsa'
import AgregarDisposición from './Components/Disposiciones/AgregarDisposicion';
import SolicitudesView from './Components/Solicitudes/SolicitudesView';
import AgregarSolicitud from './Components/Solicitudes/AgregarSolicitud';
import EditarDisposicion from './Components/Disposiciones/EditarDisposicion';
import RecoleccionesView from './Components/Recolecciones/RecoleccionesView'
import DisposicionView from './Components/Disposiciones/DisposicionView';
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import Navbar from './Components/Navbar';
import Display from './Components/Solicitudes/Display'
import AddLocation from './Components/Locations/AddLocation';
import AddClient from './Components/Clients/AddClient'
import PlanSemanal from './Components/PlanSemanal/PlanSemanal';
import AgregarRecoleccionTerminada from './Components/Recolecciones/AgregarRecoleccionTerminada';
import Estadisticas from './Components/Estadisticas/Estadisticas';
import Admin from './Components/Admin/Admin';
import AddCampaign from './Components/Campaign/AddCampaign';
import Report from './Components/Report/Report';
import NotAuth from './Components/NotAuthorized/NotAuth';

function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const localStorageInfo = localStorage.getItem("token");
    if (localStorageInfo) {
      setToken(localStorageInfo);
    }
  }, []);

  const isAuthenticated = token !== null;

  return (
    <div className="App">
      {isAuthenticated && <Navbar setToken={setToken} token={token} />}
      <Routes>
        <Route
          path="/"
          element={
            (isAuthenticated && isUserAuthorized(jwt(token).roles)) ? (
              <Navigate to="/plan-semanal" replace />
            ) : (isAuthenticated && !isUserAuthorized(jwt(token).roles)) ? (
              <NotAuth />
            ) : (
              <HomeLogOff />
            )
          }
        />
        {(isAuthenticated && isUserAuthorized(jwt(token).roles)) ? (
          <>
            <Route path='/solicitud-recolecciones' element={<SolicitudesView />} />
            <Route path='/solicitudes/:id/' element={< Display />} />
            <Route path='/new-solicitud' element={<AgregarSolicitud />} />
            <Route path='/recolecciones/:idrec/new' element={<AgregarBolsa />} />
            <Route path='/disposiciones' element={<DisposicionesView />} />
            <Route path='/new-disposicion' element={<AgregarDisposición />} />
            <Route path='/disposiciones/:id/edit' element={<EditarDisposicion />} />
            <Route path='/recolecciones' element={<RecoleccionesView />} />
            <Route path='/recolecciones/new' element={<AgregarRecoleccionTerminada />} />
            <Route path='/recolecciones/:idrec/bolsas' element={<BolsasView />} />
            <Route path='/recolecciones/:idrec/bolsas/:idbolsa' element={<SegregacionBolsa />} />
            <Route path='/recolecciones/:idrec/bolsas/:idbolsa/segregationView' element={<SegregacionView />} />
            <Route path='/disposiciones/:id' element={<DisposicionView />} />
            <Route path='/disposiciones/:id/new' element={<AgregarBolsaDisposicion />} />
            <Route path='/add-location' element={<AddLocation />} />
            <Route path='/add-client' element={<AddClient />} />
            <Route path='/plan-semanal' element={<PlanSemanal />} />
            <Route path='/estadisticas' element={<Estadisticas />} />
            <Route path='/admin' element={<Admin />} />
            <Route path='/new-campaign' element={<AddCampaign />} />
            <Route path='/report' element={<Report />} />
          </>
        ): (
          <>
            <Route path='/not-authorized' element={<NotAuth setToken={setToken} token={token} />} />
          </>
        )}
        <Route path='/login' element={<Login setToken={setToken} token={token} />} />
        <Route path='/signup' element={<Register token={token} setToken={setToken} />} />
      </Routes>
    </div>
  );
}

export default App;
