import React, { useEffect, useState } from "react";
import apiClient from "../Services/apiClient";
import { Link } from "react-router-dom";
import { locations_from_client } from "../Helpers/Formulas"
import '../../Estilos/PostForm.css';
import '../../Estilos/solicitudes.css'
import '../../App.css'

export default function AddLocation() {
  const [clientID, setClientID] = useState(0);
  const [clients, setClients] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationID, setLocationID] = useState(0);
  const [identityCode, setIdentityCode] = useState("");
  const [responsable, setResponsable] = useState("");
  const [phone, setPhone] = useState("");
  const [routes, setRoutes] = useState([]);
  const [routeID, setRouteID] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignID, setCampaignID] = useState(0);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceTypeID, setServiceTypeID] = useState(0);
  const [eta, setETA] = useState(null);

  async function createNewRequest() {
    try {
      const response = await apiClient.post("/service_schedules", {
        data: {
          service_type_id: parseInt(serviceTypeID),
          location_id: parseInt(locationID),
          route_id: null,
          campaign_id: parseInt(campaignID),
          collected: false,
          scheduled_at: null,
          estimated_time_minutes: parseInt(eta)
        }
      });
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function getClients() {
    try {
      const response = await apiClient.get("/clients");
      setClients(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function getLocations() {
    try {
      const response = await apiClient.get(`/locations?all=false`);
      setLocations(locations_from_client(clientID, response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        console.log(error);
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function getClientInfo() {
    try {
      const response = await apiClient.get(`/clients/${clientID}`);
      setIdentityCode(response.data.RBD || response.data.RUT || "0000");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function getLocationInfo() {
    try {
      const response = await apiClient.get(`/locations/${locationID}?all=true`);
      setResponsable(response.data.contact.name);
      setPhone(response.data.contact.phone);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function getRoutes() {
    try {
      const response = await apiClient.get("/routes?all=false");
      setRoutes(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function getCampaigns() {
    try {
      const response = await apiClient.get("/campaigns");
      setCampaigns(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  async function getServiceTypes() {
    try {
      const response = await apiClient.get("/service_types");
      setServiceTypes(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }


  const displayClientOptions = clients.map((client) => {
    return (
      <option key={client.id} value={client.id}>{client.name}</option>
    )
  })

  const displayClientLocations = locations.map((location) => {
    return (
      <option key={location.id} value={location.id}>{location.address}</option>
    )
  })

  const displayRoutes = routes.map((route) => {
    return (
      <option key={route.id} value={route.id}>{route.name}</option>
    )
  })

  const campaignOptions = campaigns.map((campaign) => {
    if (campaign.active) {
      return (
        <option key={campaign.id} value={campaign.id}>{campaign.name}</option>
      )
    }
  })

  const serviceOptions = serviceTypes.map((service) => {
    return (
      <option key={service.id} value={service.id}>{service.name}</option>
    )
  })



  useEffect(() => {
    getClients();
    getRoutes();
    getCampaigns();
    getServiceTypes();
  }, []);

  useEffect(() => {
    if (clientID !== 0) {
      getClientInfo();
      getLocations();
    }
  }, [clientID]);

  useEffect(() => {
    if (locationID !== 0) {
      getLocationInfo();
    }
  }, [locationID]);

  return (
    <div className="post-form">
      <div className="form-container">
        <div className="form-title">
          <h1 className="title">Crear nueva solicitud de recolección</h1>
        </div>

        <hr></hr>

        <div className="display-elements">

          <div className="location-elements">
            <div className="group">
              <label style={{ fontFamily: 'Dosis' }}>Cliente</label>
              <div className="select-style">
                <select name="clients" id="clients" onChange={(e) => { setClientID(e.target.value) }}>
                  <option value="0">Seleccione un cliente</option>
                  {displayClientOptions}
                </select>
              </div>
              <a href="/add-client">Agregar nuevo cliente</a>
            </div>

            <div className="group" style={{
              marginLeft: '0',
            }}>
              <label style={{ fontFamily: 'Dosis' }}>RUT / RBD:</label>
              <p style=
                {{
                  borderRadius: '1vh',
                  backgroundColor: '#EBEBEB',
                  fontFamily: 'Dosis',
                  color: 'A7A7A7',
                  marginLeft: '0'
                }}>{identityCode || "Seleccione Cliente"}</p>
            </div>


            <div className="group">
              <label>Destino</label>
              <div className="select-style">
                <select name="clients" id="clients" onChange={(e) => { setLocationID(e.target.value) }}>
                  <option value="0">Seleccione un destino</option>
                  {displayClientLocations}
                </select>
              </div>
              <a href="/add-location">¿No ves tu ubicación? ¡Agrégala!</a>
            </div>
            <div className="group">
              <label>Responsable:</label>
              <p>{responsable || "Seleccione un destino"}</p>
            </div>
            <div className="group">
              <label>Teléfono:</label>
              <p>{phone || "Seleccione un destino"}</p>
            </div>
          </div>
          <div className="aditional-elements">
            <div className="campaign-elements">
              <div className="group">
                <label>Campaña</label>
                <div className="select-style">
                  <select name="clients" id="clients" onChange={(e) => { setCampaignID(e.target.value) }}>
                    <option value="0">Seleccione una campaña</option>
                    {campaignOptions}
                  </select>
                </div>
              </div>
            </div>

            <div className="service-elements">
              <div className="group">
                <label>Servicio</label>
                <div className="select-style">
                  <select name="serviceTypes" id="serviceTypes" onChange={(e) => { setServiceTypeID(e.target.value) }}>
                    <option value="0">Seleccione un servicio</option>
                    {serviceOptions}
                  </select>
                </div>
              </div>
            </div>
            <div className="time-elements">
              <div className="group">
                <label>Tiempo estimado:</label>

                <input type="text" id="recoleccionName" name="recoleccionName" placeholder="minutos" onChange={(e) => setETA(e.target.value)}></input>

              </div>
            </div>
          </div>
        </div>

        <div className="option-buttons">
          <Link className="button" id="cancel" to={'/solicitud-recolecciones'}>Volver</Link>
          <Link className="button" id="confirm" to={'/solicitud-recolecciones'} onClick={createNewRequest}>Confirmar</Link>
        </div>
      </div>
    </div >
  )
}