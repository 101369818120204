import React from 'react';
import BolsasDisposiciones from './BolsasDisposiciones'
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import '../../Estilos/Bolsas.css'
import '../../App.css'


function DisposicionView(props) {

  let { id } = useParams();
  const navigate = useNavigate();

  return (
    <div className='body'>

      <div className='agregar-bolsa'>
        <Link to="./new">
          <button className='boton-agregar'>Ingresar Bolsa</button>
        </Link>
      </div>

      <div className='container-view'>
        <h1 className='titulo-container'>Disposiciones registradas</h1>

        <hr></hr>

        <div className="bolsas-container">
          <BolsasDisposiciones />
        </div>
      </div>

      <div>
        <Link to="/disposiciones">
          <button className="button" id="back">Volver</button>
        </Link>
      </div>

    </div>
  );
}

export default DisposicionView;