import React, { useEffect, useState } from "react";
import moment from 'moment';
import "moment/locale/es";
import { fetchElement } from '../Helpers/Formulas';
import apiClient from "../Services/apiClient";

export default function Repeat({ routeId }) {
    moment.locale('es');

    const [times, setTimes] = useState(1);
    const [period, setPeriod] = useState(7);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");


    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            'times': parseInt(times),
            'period': parseInt(period),
        };

        try {
            const response = await apiClient.post(`/routes/repeat/${routeId}`, data);
            if (response.status === 200) {
                setPopupMessage('¡Tu ruta se ha duplicado exitosamente!');
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                    // Realizar redirección a "/plan-semanal" después de 3 segundos
                    window.location.href = "/plan-semanal";
                }, 3000);
            }

        } catch (error) {
            alert(error.response.data.errors);
            // Procesar error de la solicitud
        }
    };

    return (
        <div className='post-form'>
            {showPopup && (
                <div className="popup">
                    <p>{popupMessage}</p>
                </div>
            )}

            <div className='form-title'>
                <h1 className='title' style={{ margin: 0 }}>Repetir ruta</h1>
            </div>

            <hr></hr>

            <div className='form'>
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label>¿Cada cuánto se debería repetir?</label>
                        <select className='select-style-new-route' value={period} onChange={e => setPeriod(e.target.value)} required>
                            <option value="7" >Una vez a la semana</option>
                            <option value="14" >Semana por medio</option>
                            <option value="28" >Cada 4 semanas</option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <label>¿Cuántas veces se debería repetir?</label>
                        <input className='select-style-new-route' type='number' value={times} onChange={(e) => setTimes(e.target.value)} required />
                    </div>
                    <button className="button" id="confirm" type='submit'>Confirmar</button>
                </form>
            </div>
        </div>
    );
}
