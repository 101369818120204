// Imports necesarios //
// PLANIFICACIÓN SEMANAL //
import moment from "moment-timezone";
import apiClient from "../Services/apiClient";
///////////////////////////


export function has_segregation(arr) {
    var seg = ""
    if (arr.length > 0) {
        seg = "Segregada";
    }
    else {
        seg = "Pendiente";
    }
    return seg;
};

export function locations_from_client(client_id, locations) {

    client_id = parseInt(client_id);
    console.log(locations);
    var clientLocations = [];
    for (var i = 0; i < locations.length; i++) {
        if (locations[i]["client_id"] === client_id) {
            clientLocations.push(locations[i]);
        }
    }
    console.log(clientLocations);
    return clientLocations;
}

// GENERAL //
export async function fetchElement(endpoint, setConstant) {
    try {
        const response = await apiClient.get(endpoint);
        setConstant(response.data);
    } catch (error) {
        if (error.response && error.response.status === 401) {
            alert("Token Inválido. No puedes realizar esta acción.");
        } else {
            alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        }
        return false;
    };
};


// VISTA PLANIFICACIÓN SEMANAL //
//// Para traducir los días del inglés al español ////
export function traductor(day, english, spanish) {
    const daysTranslation = {
        Monday: 'Lunes',
        Tuesday: 'Martes',
        Wednesday: 'Miércoles',
        Thursday: 'Jueves',
        Friday: 'Viernes',
        Saturday: 'Sábado',
        Sunday: 'Domingo',
    };
    const dayName = day.split(' ')[0]
    const dayNumber = day.split(' ')[1]

    if (dayName in daysTranslation) {
        if (english) {
            return day;
        } else if (spanish) {
            return daysTranslation[dayName] + ' ' + dayNumber;
        }
    }

    return day;
};

//// Para que en cada columna de día se vean solo las rutas programadas para ese día ////
export function filtrarRutas(day, rutas) {
    const fecha_columna = day.format('YYYY-MM-DD');
    return rutas.filter((ruta) => {
        const fecha_ruta = moment(ruta.scheduled_at).format('YYYY-MM-DD');
        return fecha_columna === fecha_ruta;
    });
};

//// Para que, según el filtro semanal, devuelva los nombres con el número de los días de tal semana ////
export function getDayTitles(selectedWeek) {
    const today = moment().startOf('isoWeek');
    const selectedStartOfWeek = selectedWeek ? moment(selectedWeek).startOf('isoWeek') : today;

    if (!selectedWeek) {
        return Array.from({ length: 7 }).map((_, index) => {
            const date = moment().startOf('isoWeek').add(index, 'days');
            const day = date.format('dddd D');
            const daySpanish = traductor(day, false, true);
            const isToday = date.isSame(moment(), 'day');

            return {
                day,
                daySpanish,
                isToday,
                date
            };
        })
    }

    return Array.from({ length: 7 }).map((_, index) => {
        const date = moment(selectedStartOfWeek).add(index, 'days');
        const day = date.format('dddd D');
        const daySpanish = traductor(day, false, true);
        const isToday = date.isSame(moment(), 'day');

        return {
            day,
            isToday,
            daySpanish,
            date
        };
    })
};

//// Según un id proporcionado, devuelve un color pastel ////
export function getColorPastelById(id) {
    const coloresPastel = [
        '#fabfb7',
        '#ffca99',
        '#ffda89',
        '#b0f2c2',
        '#95b8f6',
        '#dcd9f8',
        '#f7cae4',
    ];

    const colorPastelIndex = (id - 1) % coloresPastel.length;
    const colorPastel = coloresPastel[colorPastelIndex];

    return colorPastel;
};

//// Según id de tipo de servicio, devuelve un texto y un color ////
export function getTipoServicioById(id) {
    let tipoServicioColor = "";
    let tipoServicioTexto = "";

    if (id === 1) {
        tipoServicioColor = "palegreen";
        tipoServicioTexto = "Retiro";
    } else if (id === 2) {
        tipoServicioColor = "lightcoral";
        tipoServicioTexto = "Entrega";
    }

    return [tipoServicioColor, tipoServicioTexto];
};

//// Formato dd-mm-yyyy de fecha ////
export function formatDate(dateString) {
    const createdDate = new Date(dateString);
    const day = createdDate.getDate();
    const month = createdDate.getMonth() + 1;
    const year = createdDate.getFullYear();
    const formattedDate = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;

    return formattedDate;
}

//// Formato Dddd DD de mmmm de yyyy ////
function obtenerFechaEscrita(fechaStr) {
    const diasSemana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    const meses = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];

    const partesFecha = fechaStr.split("-");
    const año = parseInt(partesFecha[0]);
    const mes = parseInt(partesFecha[1]) - 1;
    const dia = parseInt(partesFecha[2]);

    const fecha = new Date(año, mes, dia);
    const diaSemana = diasSemana[fecha.getDay()];
    const fechaFormateada = `${diaSemana} ${dia} de ${meses[mes]} de ${año}`;

    return fechaFormateada;
}

export default obtenerFechaEscrita;
/////////////////////////////////////////////////////////////////////////////////////////////////////////////