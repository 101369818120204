export function orderStats(materialesDict) {
    let matArr = [];
    let statsDataArr = [["Materiales", "Cantidad"]];
    Object.keys(materialesDict).forEach(function (key) {
        matArr.push(materialesDict[key]);
        statsDataArr.push([materialesDict[key].name, materialesDict[key]["kg_collected"]]);
    });
    return [matArr, statsDataArr];
}

export const meses = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" },
];

export const pastelColors = [
    "#FF8A80", // Light Red
    "#FAAE65", // Light Orange
    "#FFD165", // Light Yellow
    "#A5D988", // Light Lime
    "#81C784", // Light Green
    "#4FC3F7", // Light Blue
    "#7986CB", // Light Indigo
    "#CE93D8", // Light Purple
    "#F48FB1", // Light Pink
    "#FFD180", // Light Peach
    "#AED581", // Light Lime Green
    "#4DD0E1", // Light Cyan
    "#9575CD", // Light Lavender
    "#FFAB91", // Light Salmon
    "#FFAB40", // Light Amber
];

export function chunkArray(array, size) {
    const chunkedArray = [];
    let index = 0;
    while (index < array.length) {
        chunkedArray.push(array.slice(index, index + size));
        index += size;
    }
    return chunkedArray;
}