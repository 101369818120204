import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import apiClient from "../Services/apiClient";
import '../../Estilos/PostForm.css';
import '../../App.css'

async function SubmmitDisposicion (destinosarr) {

  var inputs = document.getElementsByClassName("input");
  var bool = true;
  for(var i = 0; i < inputs.length; i++){
      if(inputs[i].value === ""){
          bool = false;
      }
  }
  var valuestr = document.getElementsByClassName("value")[0].value;
  var value = parseInt(valuestr);
  if(value < 0){
    alert("El valor no puede ser negativo");
  }
  else if(bool){

      var tipostr = document.getElementsByClassName("type")[0].value;
      var datestr = document.getElementsByClassName("date")[0].value;
      var clientestr = document.getElementsByClassName("destiny")[0].value;

      let client_id = 0;

      for(i = 0; i < destinosarr.length; i++){
          if(destinosarr[i]["name"] === clientestr){
            client_id = destinosarr[i]["id"];
          }
      }
      if(tipostr != "Venta"){
        value = 0 - value;
      }
      var dateArr = datestr.split("-");
      datestr = new Date(
        parseInt(dateArr[0]),
        parseInt(dateArr[1])-1,
        parseInt(dateArr[2]),
        1,
        0,
        0
      );
      const data = {
          "data": {
            "receiver_client_id": client_id,
            "delivered_at": datestr,
            "price": value
          }
      };    

      try {
        await apiClient.post("/sales", data);
        return true;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert("Token Inválido. No puedes realizar esta acción.");
        } else {
          alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        }
        return false;
      }
    } else {
      alert("NO PUEDES DEJAR NADA VACIO");
    }
  }

function AgregarDisposición() {

  let navigate = useNavigate();

  const [clientes, setClientes] = useState([]);
  useEffect(() => {
    async function fetchClientes() {
      try {
        const response = await apiClient.get("/clients");
        setClientes(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert("Token Inválido. No puedes realizar esta acción.");
        } else {
          alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        }
        return false;
      }
    }
    fetchClientes();
  }, []);

  return (
    <div className="post-form">
      <div className="form-container">
        <div className="form-title">
          <h1 className="title">Ingresar disposición</h1>
        </div>

        <hr></hr>

        <div className="form-items">

          <div className="form-group">
            <label for="Tipo">Tipo</label>
            <input type="text" id="diposicionType" name="disposicionType" list="type" className="type input" />
            <datalist id="type">
              <option>Venta</option>
              <option>Desechar</option>
            </datalist>
          </div>

          <div className="form-group">
            <label for="Fecha">Fecha</label>
            <input type="date" id="disposicionDate" name="disposicionDate" className="date input" />
          </div>

          <div className="form-group">
            <label for="Destino">Cliente Receptor</label>
            <input type="text" id="disposicionDestiny" name="disposicionDestiny" list="locations" className="destiny input" />
            <datalist id="locations">
                {clientes.map((option) => (
                <option value={option["name"]}></option>
                ))}
            </datalist>
          </div>

          <div className="form-group">
            <label for="Valor">Valor</label>
            <input type="text" id="disposicionValue" name="disposicionValue" className="value input" />
          </div>

        </div>

        <div className="option-buttons">
          <Link className="button" id="cancel" to={'/disposiciones'}>Cancelar</Link>
          <button className="button" id="confirm" onClick={async () => {
            const respuesta = await SubmmitDisposicion(clientes);
            if(respuesta){
                navigate("/disposiciones");
            }
            else{
                alert("HUBO UN ERROR");
            }
          }} >Confirmar</button>
        </div>
      </div>
    </div >
  );
}

export default AgregarDisposición