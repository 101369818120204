import React from 'react';
import Bolsa from './Bolsa.jsx'
import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import apiClient from "../Services/apiClient";
import {has_segregation} from "../Helpers/Formulas"
import '../../Estilos/Bolsas.css'

function armarBolsa(params) {
  const seg = has_segregation(params["segregation_pickup_bags_residue_types"]);
  return (
    <Bolsa
      id={params["id"]}
      tipo={params["residue_type"]["name"]}
      peso={params["weight_pickup_kg"]}
      seg={seg}
    />
  );
}

function BolsasView(props) {

  let { idrec } = useParams();

  const [bolsas, setBolsas] = useState([]);

  useEffect(() => {
    const fetchBolsas = async () => {
      try {
        const response = await apiClient.get(
          `/service_schedules/${idrec}/pickup_bags?all=true`
        );
        console.log(response.data);
        setBolsas(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert("Token Inválido. No puedes realizar esta acción.");
        } else {
          alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        }
        return false;
      }
    };

    fetchBolsas();
  }, []);

  return(
    <div className='body'>

      <div className='agregar-bolsa'>
        <Link to={`/recolecciones/${idrec}/new`}>
          <button className='boton-agregar'> Agregar Bolsa </button>
        </Link>
      </div>

      <div className='container-view'>
        <h1 className='titulo-container'>Bolsas de la recolección</h1>

        <hr></hr>

        <div className='bolsas-container'>
          <div className="table-container">
            <div className="table">
              <div className="row" id="header">
                <div className="header" id="bag-row">Tipo de residuo</div>
                <div className="header" id="bag-row">Peso [Kg]</div>
                <div className="header" id="bag-row">Segregada</div>
                <div className="header" id="bag-row"></div>
                <div className="header" id="bag-row">Ver Segregación</div>
              </div>
              {bolsas.map((bolsa) => armarBolsa(bolsa))}
            </div>
          </div>
        </div>

        <div>
          <Link to="/recolecciones">
            <button className="button" id="back">Volver</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BolsasView;