import React from 'react';
import Solicitudes from './Solicitudes'
import { Link } from "react-router-dom";
import '../../Estilos/Bolsas.css'
import '../../App.css'

function SolicitudesView(props) {

  return (
    <div className='body'>

      <p className='instructions'>Aquí podrás ver el detalle de las solicitudes pendientes</p>

      <div className='agregar-bolsa'>
        <Link to="/new-solicitud">
          <button className='boton-agregar'>Ingresar nueva solicitud de recolección</button>
        </Link>
      </div>

      <div className='container-view'>
        <h1 className='titulo-container'>Solicitudes de recolección ingresadas</h1>

        <hr></hr>

        <div className="bolsas-container">
          <Solicitudes />
        </div>
      </div>

    </div>
  );
}

export default SolicitudesView;