import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import apiClient from "../Services/apiClient";
import InputSeg from "../Inputs/InputSegregacion"
import '../../Estilos/PostForm.css';
import AddIcon from '@mui/icons-material/Add';

async function SumbitSegregacion (idrec, idbolsa, tiposarr) {
  var inputs = document.getElementsByClassName("input");
  var bool = true;
  for(var i = 0; i < inputs.length; i++){
      if(inputs[i].value === ""){
          bool = false;
      }
  }
  var negativos = false;
  var wheightstr = document.getElementsByClassName("weight");
  for(i = 0; i < wheightstr.length; i++){
    var peso = parseFloat(wheightstr[i].value);
    if(peso < 0){
      negativos = true;
    }
  }
  if(parseFloat(document.getElementsByClassName("wheight-in")[0].value) < 0){
    negativos = true;
  }
  if(negativos){
    alert("No puedes ingresar pesos negativos");
  }
  else if(bool){

      var tipostr = document.getElementsByClassName("residue-type");
      var wheightstr = document.getElementsByClassName("weight");

      for(var i = 0; i < tipostr.length; i++){

        var tipo = tipostr[i].value;
        var peso = wheightstr[i].value;
        
        let type_id = 0;

        for(var j = 0; j < tiposarr.length; j++){
            if(tiposarr[j]["name"] === tipo){
                type_id = tiposarr[j]["id"];
            }
        }

        const data = {
            "data": {
                "residue_type_id": type_id,
                "weight_kg": parseFloat(peso)
            }
        };
        console.log(data);
        try {
          await apiClient.post(`/service_schedules/${idrec}/pickup_bags/${idbolsa}/segregation`, data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            alert("Token Inválido. No puedes realizar esta acción.");
          } else {
            alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
          }
          return false;
        }
      }
      return true;
    } else {
      alert("NO PUEDES DEJAR NADA VACIO");
    }
  }

function SegregacionBolsa(props) {

  const navigate = useNavigate();

  let { idrec, idbolsa } = useParams();

  const [inputs, setInputs] = useState([]);

  function addInput() {
    setInputs(inputs.concat(<InputSeg key={inputs.length} />));
  }

  const [tipos, setTipos] = useState([]);
  useEffect(() => {
    apiClient.get("/residue_types")
      .then(function (response) {
        setTipos(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // Acá deberiamos conseguir los datos del backend al conectarnos a la BBD.
  // Por lo mismo el botón confirmar solo devuelve al path de inicio. Al conectar a bdd deberia haber
  // un handler acá para poder almacenar los datos ingresados por el usuario.

  return (
    <div>
      <div className="post-form">
        <div className="form-container">
          <div className="form-title">
            <h1 className="title">Segregar bolsa con ID {idbolsa}</h1>
          </div>

          <hr></hr>

          <div className="seg-container">
            <div className="form-items-seg">
              <div className="form-group">
                <label for="name">Nombre responsable</label>
                <input type="text" id="name" name="nombre" className="input"/>
              </div>
              <div className="form-group">
                <label for="name">Fecha segregación</label>
                <input type="date" id="name" name="nombre"/>
              </div>
              <div className="form-group">
                <label for="name">Peso ingreso [Kg]</label>
                <input type="number" id="name" name="nombre" className="input wheight-in"/>
              </div>
            </div>

            <div className='large-vl'></div>

            <div className="form-items-seg">
              <div className="form-group">
                <label for="name">Hora inicio</label>
                <input type="time" id="name" name="nombre" />
              </div>
              <div className="form-group">
                <label for="name">Hora término</label>
                <input type="time" id="name" name="nombre" />
              </div>
            </div>
          </div>

          <hr></hr>

          <div className="table-headers">
            <label for="name">Tipo de material</label>
            <div class="vl"></div>
            <label for="name">Peso [Kg]</label>
          </div>

          <hr></hr>

          <div className="edit-items">
            <div>
              <button className="add-fields-button" onClick={addInput}><AddIcon></AddIcon></button>
            </div>
            <div>
              {inputs}
            </div>
          </div>

          <div className="option-buttons">
            <Link className="button" id="cancel" to={`/recolecciones/${idrec}/bolsas`}>Volver</Link>
            <button className="button" id="confirm" onClick={async () => {
              const respuesta = await SumbitSegregacion(idrec, idbolsa, tipos);
              if(respuesta){
                  navigate(`/recolecciones/${idrec}/bolsas`);
              }
              else{
                  alert("HUBO UN ERROR");
              }
            }} >Confirmar</button>
          </div>
        </div>
      </div>
    </div >
  );
}

export default SegregacionBolsa;