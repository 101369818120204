import React, { useState, useEffect } from "react";
import apiClient from "../Services/apiClient";
import { Chart } from "react-google-charts";
import '../../Estilos/Estadisticas/estadisticas.css'

export default function FlujoMensual() {
  const [clients, setClients] = useState([]);
  const [clientID, setClientID] = useState(0);
  const [stats, setStats] = useState();
  const [isLoading, setLoading] = useState(true);

  const monthsDict = {
    "1": "Enero",
    "2": "Febrero",
    "3": "Marzo",
    "4": "Abril",
    "5": "Mayo",
    "6": "Junio",
    "7": "Julio",
    "8": "Agosto",
    "9": "Septiembre",
    "10": "Octubre",
    "11": "Noviembre",
    "12": "Diciembre",
  }

  const options = {
    hAxis: {
      title: "Meses",
    },
    vAxis: {
      title: "Kg",
    },
    series: {
      1: { curveType: "function" },
    },
    backgroundColor: "transparent",
  }

  async function getDataForEachMonth() {
    let statsData = [["x", "Entrada de material", "Salida de material"]];
    try {
      for (let i=1; i<13; i++) {
        const response = await apiClient.get("/statistics?client=0&year=" + new Date().getFullYear() + "&month=" + i);
        statsData.push([monthsDict[i], parseInt(response.data["total_kg_collected"]), parseInt(response.data["total_kg_sold"])]);
      }
      setLoading(false);
      setStats(statsData);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  useEffect(() => {
    getDataForEachMonth();
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <div className="stats-container">
      <h3>Flujo Mensual</h3>
      <Chart
        chartType="LineChart"
        data={stats}
        options={options}
        width={"700px"}
        height={"250px"}
      />
    </div>
  )
}