import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../Services/apiClient";
import '../../Estilos/PostForm.css';
import '../../App.css'
import { Link, useNavigate } from "react-router-dom";

async function SubmitEdit (id) {
  var datestr = document.getElementById("diposicionDate").value;
  var value = document.getElementsByClassName("disposicionValue")[0];

  if(value.value === ""){
    value = value.placeholder;
  }
  else{
    value = value.value;
  }

  var dateArr = datestr.split("-");
      datestr = new Date(
        parseInt(dateArr[0]),
        parseInt(dateArr[1])-1,
        parseInt(dateArr[2]),
        0,
        0,
        0
      );

  const data = {
    "data": {
        "price": parseInt(value),
        "delivered_at": datestr
    }
  };    
  try {
    const response = await apiClient.patch(`/sales/${id}`, data);
    return true;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      alert("Token Inválido. No puedes realizar esta acción.");
    } else {
      alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
    }
    return false;
  }
}

function EditarDisposicion() {
  const { id } = useParams();
  const [fecha, setFecha] = useState();
  const [valor, setValor] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(`/sales/${id}?all=true`);
        setFecha(response.data.created_at.slice(0, 10));
        setValor(response.data.price);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert("Token Inválido. No puedes realizar esta acción.");
        } else {
          alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        }
        return false;
      }
    };

    fetchData();
  }, [id]);

  return (
    
    <div className="post-form">
      <div className="form-container">
        <div className="form-title">
          <h1 className="title">Editar disposición </h1>
        </div>

        <hr></hr>

        <div className="form-items">
          <div className="form-group">
            <label for="Fecha">Fecha de retiro</label>
            
            <input type="date" id="diposicionDate" name="disposicionDate" min="01-01-2023"
            defaultValue={fecha} />
            <span clss="validity"></span>
          </div>

          <div className="form-group">
            <label for="Value"> Valor</label>
            <input type="number" id="disposicionValue" name="disposicionValue" className="disposicionValue" placeholder={valor}/> 
          </div>
        </div>

        <div className="option-buttons">
        
          <Link className="button" id="cancel" to={'/disposiciones'}>Cancelar</Link>
          <button className="button" onClick={async () => {
            const respuesta = await SubmitEdit(id);
            if(respuesta){
                navigate("/disposiciones");
            }
            else{
                alert("HUBO UN ERROR");
            }
          }} id="confirm" to={'/disposiciones'}>Confirmar</button>
        </div>
      </div>
    </div >
  );
}

  

export default EditarDisposicion;

