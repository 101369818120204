import React, { useState } from "react";
import apiClient from "../Services/apiClient";
import { Link } from "react-router-dom";


export default function AddCampaign() {
  const [name, setName] = useState("");

  async function createNewCampaign() {
    try {
      const response = await apiClient.post("/campaigns", {
        data: {
          name: name,
        },
      });
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  }

  return (
    <div className="post-form">
      <div className="form-container">
        <div className="form-title">
          <h1 className="title">Crear nueva campaña</h1>
        </div>

        <hr></hr>
        <div className="display-elements">
          <input className="custom-input" type="text" id="recoleccionName" name="recoleccionName" placeholder="Nombre Campaña" onChange={(e) => setName(e.target.value)}></input>
        </div>

        <div className="option-buttons">
          <Link className="button" id="cancel" to={'/admin'}>Volver</Link>
          <Link className="button" id="confirm" to={'/admin'} onClick={createNewCampaign}>Confirmar</Link>
        </div>
      </div>
    </div >
  )
}