import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import apiClient from "../Services/apiClient";
import { locations_from_client } from "../Helpers/Formulas"
import '../../Estilos/PostForm.css';
import '../../App.css'

async function SubmitRecoleccion (ubis, camps) {

  var inputs = document.getElementsByClassName("input");
  var bool = true;
  for(var i = 0; i < inputs.length; i++){
      if(inputs[i].value === ""){
          bool = false;
      }
  }
  
  if(bool){

      var clientestr = document.getElementsByClassName("client")[0].value;
      var ubicstr = document.getElementsByClassName("location")[0].value;
      var campstr = document.getElementsByClassName("campaign")[0].value;
      var datestr = document.getElementsByClassName("date")[0].value;

      let loc_id = 0;
      for(i = 0; i < ubis.length; i++){
          if(ubis[i]["address"] === ubicstr){
            loc_id = ubis[i]["id"];
          }
      }

      let camp_id = 0;
      for(i = 0; i < camps.length; i++){
        if(camps[i]["name"] === campstr){
          camp_id = camps[i]["id"];
        }
      }

      var dateArr = datestr.split("-");
      datestr = new Date(
        parseInt(dateArr[0]),
        parseInt(dateArr[1])-1,
        parseInt(dateArr[2]),
        1,
        0,
        0
      );

      const data = {
          "data": {
            "service_type_id": 1,
            "location_id": loc_id,
            "campaign_id": camp_id,
            "collected": true,
            "weighted": true,
            "collected_at": datestr
          }
      };    
      try {
        await apiClient.post("/service_schedules", data);
        return true;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert("Token Inválido. No puedes realizar esta acción.");
        } else {
          alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        }
        return false;
      }
    } else {
      alert("NO PUEDES DEJAR NADA VACIO");
    }
  }
  

function AgregarRecoleccionTerminada() {

    let navigate = useNavigate();

    const [clientes, setClientes] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);
    const [campanas, setCampanas] = useState([]);
    useEffect(() => {
        apiClient.get("/clients")
          .then(function(response) {
            setClientes(response.data);
          })
          .catch(function(error) {
            console.log(error);
          });
      }, []);
    
      useEffect(() => {
        apiClient.get("/locations")
          .then(function(response) {
            setUbicaciones(response.data);
          })
          .catch(function(error) {
            console.log(error);
          });
      }, []);
    
      useEffect(() => {
        apiClient.get("/campaigns")
          .then(function(response) {
            setCampanas(response.data);
          })
          .catch(function(error) {
            console.log(error);
          });
      }, []);

    return (
        <div className="post-form">
        <div className="form-container">
            <div className="form-title">
                <h1 className="title">Ingresar Recolección</h1>
            </div>

            <hr></hr>

            <div className="form-items">

            <div className="form-group">
                <label for="Cliente">Cliente</label>
                <input type="text" id="recClient" name="recClient" list="clients" className="client input" />
                <datalist id="clients">
                    {clientes.map((option) => (
                    <option value={option["name"]}></option>
                    ))}
                </datalist>
            </div>

            <div className="form-group">
                <label for="Ubicacion">Ubicación</label>
                <input type="text" id="recLocation" name="recLocation" list="locations" className="location input" />
                <datalist id="locations">
                    {ubicaciones.map((option) => (
                    <option value={option["address"]}></option>
                    ))}
                </datalist>
            </div>

            <div className="form-group">
                <label for="Camapaign">Camapaña</label>
                <input type="text" id="recCampaign" name="recCampaign" list="campaigns" className="campaign input" />
                <datalist id="campaigns">
                    {campanas.map((option) => {
                        if(option.active){
                            return(
                                <option value={option["name"]}></option>
                            )
                        }
                })}
                </datalist>
            </div>

            <div className="form-group">
                <label for="Fecha">Fecha</label>
                <input type="date" id="recDate" name="recDate" className="date input" />
            </div>

            </div>

            <div className="option-buttons">
            <Link className="button" id="cancel" to={'/recolecciones'}>Cancelar</Link>
            <button className="button" id="confirm" onClick={async () => {
                const respuesta = await SubmitRecoleccion(ubicaciones, campanas);
                if(respuesta){
                    navigate("/recolecciones");
                }
                else{
                    alert("HUBO UN ERROR");
                }
            }} >Confirmar</button>
            </div>
        </div>
        </div >
    );
}

export default AgregarRecoleccionTerminada