import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import apiClient from "../Services/apiClient";
import '../../Estilos/PostForm.css';
import '../../App.css';

async function SubmitNewBolsa(id, tiposarr) {

  var inputs = document.getElementsByClassName("input");
  var bool = true;
  for (var i = 0; i < inputs.length; i++) {
    if (inputs[i].value === "") {
      bool = false;
    }
  }
  var wheight = parseFloat(document.getElementsByClassName("weight")[0].value);
  if(wheight < 0){
    alert("No puedes ingresar valores negativos");
  }
  else if (bool) {

    var tipostr = document.getElementsByClassName("type")[0].value;

    let type_id = 0;

    for (i = 0; i < tiposarr.length; i++) {
      if (tiposarr[i]["name"] === tipostr) {
        type_id = tiposarr[i]["id"];
      }
    }

    const data = {
      "data": {
        "schedule_id": parseInt(id),
        "residue_type_id": type_id,
        "weight_pickup_kg": parseFloat(wheight),
        "weight_segregation_kg": parseFloat(wheight)
      }
    };

    try {
      const response = await apiClient.post(`/service_schedules/${id}/pickup_bags`, data);
      return true;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Token Inválido. No puedes realizar esta acción.");
      } else {
        alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
      }
      return false;
    }
  } else {
    alert("No puedes dejar campos vacíos");
  }
}


export default function AgregarBolsa() {
  const navigate = useNavigate();

  let { idrec } = useParams();

  const [tipos, setTipos] = useState([]);

  useEffect(() => {
    const fetchTipos = async () => {
      try {
        const response = await apiClient.get("/residue_types");
        setTipos(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert("Token Inválido. No puedes realizar esta acción.");
        } else {
          alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        }
        return false;
      }
    };
    fetchTipos();
  }, []);

  return (
    <div className="post-form">
      <div className="form-container">
        <div className="form-title">
          <h1 className="title">Ingresar Bolsa</h1>
        </div>

        <hr></hr>

        <div className="form-items">

          <div className="form-group">
            <label for="Tipo">Tipo</label>
            <input type="text" id="diposicionType" name="disposicionType" className="input type" list="residue-types" />
            <datalist id="residue-types">
              {tipos.map((option) => (
                <option value={option["name"]}></option>
              ))}
            </datalist>
          </div>

          <div className="form-group">
            <label for="Peso">Peso</label>
            <input type="number" id="disposicionWeight" name="disposicionWeight" className="input weight" />
          </div>

        </div>

        <div className="option-buttons">
          <Link className="button" id="cancel" to={`/recolecciones/${idrec}/bolsas`}>Cancelar</Link>
          <button className="button" id="confirm" onClick={async () => {
              const respuesta = await SubmitNewBolsa(idrec, tipos);
              if (respuesta) {
                navigate(`/recolecciones/${idrec}/bolsas`);
            }
            else {
                alert("HUBO UN ERROR");
              }
          }} >Confirmar</button>
        </div>
      </div>
    </div >
  );
}