import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { fetchElement } from '../Helpers/Formulas';
import apiClient from '../Services/apiClient';

export default function NewRoute({ routeDay }) {
    moment.locale('es');

    const [vehicles, setVehicles] = useState([]);
    const [crews, setCrews] = useState([]);
    const [name, setName] = useState('');
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedCrew, setSelectedCrew] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showForm, setShowForm] = useState(true);

    const fecha = moment(routeDay).format('dddd D [de] MMMM [del] YYYY');
    const post_fecha = moment(routeDay).format('YYYY-MM-DD');

    useEffect(() => {
        fetchElement('/vehicles?all=true', setVehicles);
        fetchElement('/crews?all=true', setCrews);
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            'data': {
                'name': name,
                'vehicle_id': parseInt(selectedVehicle),
                'crew_id': parseInt(selectedCrew),
                'scheduled_at': post_fecha,
            }
        };

        try {
            const response = await apiClient.post('/routes', data);
            if (response.status === 200) {
                setPopupMessage('¡Tu ruta se ha creado exitosamente!');
                setShowPopup(true);
                setShowForm(false);

                setTimeout(() => {
                    setShowPopup(false);
                    window.location.href = "/plan-semanal";
                    setShowForm(true);
                }, 2000);
            }
        } catch (error) {
            alert(error.response.data.errors);
            // Procesar error de la solicitud
        }
    };

    return (
        <div className='post-form'>
            {showPopup && (
                <div className="popup" id='new-route'>
                    <p>{popupMessage}</p>
                </div>
            )}

            <div className='form-title'>
                <h1 className='title' style={{ margin: 0 }}>Nueva ruta para {fecha}</h1>
            </div>

            <hr />

            {showForm && (
                <div className='form'>
                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <label>Nombre de ruta</label>
                            <input type='text' value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>

                        <div className='form-group'>
                            <label>Vehículo</label>
                            <select className='select-style-new-route' name='vehicle' value={selectedVehicle} onChange={(e) => setSelectedVehicle(e.target.value)} required>
                                <option value='' disabled>Selecciona un vehículo</option>
                                {vehicles.map((vehicle) => (
                                    <option key={vehicle.id} value={vehicle.id}>
                                        {vehicle.license_plate} - {vehicle.gestor.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className='form-group'>
                            <label>Tripulación</label>
                            <select className='select-style-new-route' name='crew' value={selectedCrew} onChange={(e) => setSelectedCrew(e.target.value)} required>
                                <option value='' disabled>Selecciona un equipo</option>
                                {crews.map((crew) => (
                                    <option key={crew.id} value={crew.id}>
                                        {crew.drivers.map((driver, index) => (
                                            <React.Fragment key={driver.id}>
                                                {driver.name}
                                                {index !== crew.drivers.length - 1 && ' - '}
                                            </React.Fragment>
                                        ))}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <button className='button' id='confirm' type='submit'>Crear Ruta</button>
                    </form>
                </div>
            )}
        </div>
    );
}