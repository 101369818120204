import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import apiClient from "../Services/apiClient";
import { format } from 'date-fns';
import moment from "moment-timezone";

function DisplayForm({ requestID }) {
    const navigate = useNavigate();
    const [routes, setRoutes] = useState([]);
    const [formData, setFormData] = useState({
        route: '',
        duration: '',
        day: '',
        schedule: ''
    });
    async function getRoutes() {
        try {
            const response = await apiClient.get("/routes?all=true");
            setRoutes(response.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Token Inválido. No puedes realizar esta acción.");
            } else {
                alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
            }
            return false;
        }
    }


    async function editRequest() {
        // Formatear fecha y hora en el formato deseado
        const formattedDateTime = format(
            moment(formData.day + ' ' + formData.schedule, 'DD-MM-YYYY HH:mm').toDate(),
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
        );

        const data = {
            data: {
                route_id: parseInt(formData.route, 10),
                estimated_time_minutes: parseInt(formData.duration, 10),
                scheduled_at: formattedDateTime,
            },
        };

        try {
            const response = await apiClient.patch(`/service_schedules/${requestID}`, data);
            console.log(response.data);
            navigate('/plan-semanal', { replace: true });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Token Inválido. No puedes realizar esta acción.");
            } else {
                alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
            }
            return false;
        }
    }

    useEffect(() => {
        getRoutes();
    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleRouteChange = (e) => {
        const selectedRouteId = e.target.value;
        const selectedRoute = routes.find((route) => String(route.id) === selectedRouteId);
        if (selectedRoute) {
            setFormData((prevData) => ({
                ...prevData,
                day: formatDate(selectedRoute.scheduled_at) // Cambiar el formato de la fecha
            }));
        }
    };

    const formatDate = (dateString) => {
        const date = moment.utc(dateString);
        const day = date.format('DD');
        const month = date.format('MM');
        const year = date.format('YYYY');

        return `${day}-${month}-${year}`;
    };

    return (
        <div
            style={{
                backgroundColor: "#cce7ff",
                borderRadius: "20px",
                margin: "1%",
                padding: "1%",
                animation: "slide-down-form 0.6s 1"
            }}
        >
            <form onSubmit={editRequest} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <label htmlFor="route" style={{ marginRight: '5px' }}>Ruta:</label>
                    <select
                        id="route"
                        name="route"
                        value={formData.route}
                        onChange={(e) => {
                            handleInputChange(e);
                            handleRouteChange(e);
                        }}
                        required
                        style={{
                            borderRadius: '10px',
                            border: 'none',
                            outline: 'none',
                            padding: '5px',
                            fontFamily: 'Dosis',
                            width: '9vw',
                            boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)',
                            borderBottom: 'none',
                        }}
                    >
                        <option value="">Seleccionar ruta</option>
                        {routes.map((route) => (
                            <option key={route.id} value={route.id}>
                                {route.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <label htmlFor="duration" style={{ marginRight: '5px' }}>Duración:</label>
                    <input
                        type="number"
                        id="duration"
                        name="duration"
                        value={formData.duration}
                        onChange={handleInputChange}
                        step="5"
                        required
                        min="0"
                        style={{
                            fontFamily: 'Dosis',
                            width: '9vw',
                        }}
                    />
                    <p style={{ marginLeft: '10px' }}>[min]</p>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <label htmlFor="day" style={{ marginRight: '-30px' }}>Horario:</label>
                    <input
                        type="text"
                        id="day"
                        name="day"
                        value={formData.day}
                        onChange={handleInputChange}
                        required
                        readOnly
                        style={{
                            width: '9vw',
                            marginLeft: '0',
                            backgroundColor: '#e6f3ff',
                        }}
                    />
                    <input
                        type="time"
                        id="schedule"
                        name="schedule"
                        value={formData.schedule}
                        onChange={handleInputChange}
                        step="300" // 300 segundos = 5 minutos
                        required
                        style={{
                            width: '10vw',
                            marginLeft: '1vw'
                        }}
                    />
                </div>
                <button className='button' id='confirm' type="submit" style={{ width: '7vw', border: 'none' }}>Guardar</button>
            </form>
        </div>
    );
}

export default DisplayForm;