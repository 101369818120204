import React from "react";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import '../../Estilos/Bolsa.css';

function Bolsa(props) {
  const navigate = useNavigate();
  return (
    <div className="row">
      <div className="cell" id="bag-row">{props.tipo}</div>
      <div className="cell" id="bag-row">{props.peso}</div>
      <div className="cell" id="bag-row">{props.seg}</div>
      <div className="cell" id="bag-row">
        <Link to={`./${props.id}`}>Segregar</Link>
      </div>
      <div className="cell" id="bag-row">
        <button className="icon-link" onClick={() =>{
          if(props.seg == "Segregada"){
            navigate(`./${props.id}/segregationView`);
          }
          else {
            alert("Esta bolsa NO esta segregada todavía");
          }
        }}>
          <VisibilityIcon></VisibilityIcon>
        </button>
      </div>
    </div>
  );
}

export default Bolsa;