import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import apiClient from "../Services/apiClient";
import '../../Estilos/PostForm.css';
import '../../App.css';


async function EliminarSeg(rec, bolsa) {
  try {
    await apiClient.delete(`/service_schedules/${rec}/pickup_bags/${bolsa}/segregation`);
    return true;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      alert("Token Inválido. No puedes realizar esta acción.");
    } else {
      alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
    }
    return false;
  }
}

export default function SegregacionView() {
  const navigate = useNavigate();

  let { idrec, idbolsa } = useParams();

  const [segregacion, setSeg] = useState([]);
  useEffect(() => {
    const fetchSegregacion = async () => {
      try {
        const response = await apiClient.get(
          `/service_schedules/${idrec}/pickup_bags/${idbolsa}?all=true`
        );
        setSeg(response.data["segregation_pickup_bags_residue_types"]);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert("Token Inválido. No puedes realizar esta acción.");
        } else {
          alert("Hubo un error al enviar la solicitud. Por favor, inténtalo nuevamente.");
        }
        return false;
      }
    };

    fetchSegregacion();
  }, []);

  return (
    <div className="post-form">
      <div className="form-container">
        <div className="form-title">
          <h1 className="title">Segregación Bolsa {idbolsa}</h1>
        </div>

        <hr></hr>

        <div className="form-items">

          {segregacion.map((seg) => (
                <div className="segregacion-view">
                    <p className="segregation-text">
                        {seg["residue_type"]["name"]}: {seg["weight_kg"]}
                    </p>
                </div>
            ))}

        </div>

        <div className="option-buttons">
          <Link className="button" id="cancel" to={`/recolecciones/${idrec}/bolsas`}>Volver</Link>
          <button className="button" id="cancel" onClick={async () => {
            if(window.confirm('¿Está seguro que desea eliminar esta segregación?')){
                const respuesta = await EliminarSeg(idrec, idbolsa);
                if(respuesta){
                    navigate(`/recolecciones/${idrec}/bolsas`);
                }
                else{
                    alert("HUBO UN ERROR");
                }
            }
            }} > Eliminar </button>
        </div>
      </div>
    </div >
  );
}