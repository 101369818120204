import React, { useState, useEffect } from "react";
import Solicitud from "./Solicitud";
import apiClient from "../Services/apiClient";
import moment from 'moment-timezone';


function armarSol(params) {
  const fecha = new Date(params["created_at"]);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const fecha_formateada = fecha.toLocaleDateString('es-CL', options);
  let estado;
  if (params["scheduled_at"] === null) {
    estado = "Pendiente asignación";
  }
  else if (!params["collected"] && params["scheduled_at"] !== null) {
    estado = "Asignado";
  }
  else if (params["collected"]) {
    estado = "Recolectado";
  }

  return (
    <Solicitud
      key={params["id"]}
      id={params["id"]}
      fecha_ingreso={fecha_formateada}
      sucursal={params["location"]["address"]}
      tipo={params["service_type"]["name"]}
      estado={estado}
    />
  );
}

export default function Solicitudes() {
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage] = useState(10);
  const [filtroFecha, setFiltroFecha] = useState('');
  const [filtroSucursal, setFiltroSucursal] = useState('');

  const handleChangeFiltroFecha = (event) => {
    setFiltroFecha(event.target.value);
    setCurrentPage(1); // Reiniciar a la primera página después de cambiar el filtro
  };

  const handleChangeFiltroSucursal = (event) => {
    setFiltroSucursal(event.target.value);
    setCurrentPage(1); // Reiniciar a la primera página después de cambiar el filtro
  }

  useEffect(() => {
    apiClient.get("/service_schedules?all=true&status=not_scheduled")
      .then(function (response) {
        setRequests(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  const filteredRequests = requests.filter((request) => {
    const requestDate = moment.tz(request.created_at, 'America/Santiago').toDate();
    const fechaFiltro = moment.tz(filtroFecha, 'America/Santiago').toDate();
    const offset = requestDate.getTimezoneOffset();
    requestDate.setMinutes(requestDate.getMinutes() - offset);

    if (!filtroFecha && !filtroSucursal) return true;
    else if (!filtroFecha && filtroSucursal) return request.location.address.toLowerCase().includes(filtroSucursal.toLowerCase());
    else if (filtroFecha && !filtroSucursal)
      return (
        requestDate.getFullYear() === fechaFiltro.getFullYear() &&
        requestDate.getMonth() === fechaFiltro.getMonth() &&
        requestDate.getDate() === fechaFiltro.getDate()
      );
    else if (filtroFecha && filtroSucursal)
      return (
        requestDate.getFullYear() === fechaFiltro.getFullYear() &&
        requestDate.getMonth() === fechaFiltro.getMonth() &&
        requestDate.getDate() === fechaFiltro.getDate() &&
        request.location.address.toLowerCase().includes(filtroSucursal.toLowerCase())
      );
  });

  const totalRequests = filteredRequests.length;

  // Ajustar la página actual si está fuera de rango después de aplicar un filtro
  useEffect(() => {
    const lastPage = Math.ceil(totalRequests / requestsPerPage);
    if (currentPage > lastPage) {
      setCurrentPage(lastPage);
    }
  }, [totalRequests, requestsPerPage, currentPage]);

  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
  const currentRequests = filteredRequests.slice(indexOfFirstRequest, indexOfLastRequest);

  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1); // Establecer la página actual en 1 después de cargar las solicitudes
  }, [requests]);

  return (
    <div className="table-container">

      <div className='filters'>
        <div className='filter'>
          <label htmlFor="filtroFecha">Filtrar por fecha:</label>
          <input type="date" id="filtroFecha" onChange={handleChangeFiltroFecha} />
        </div>

        <div className='filter'>
          <label htmlFor="filtroSucursal">Filtrar por sucursal:</label>
          <input type="text" id="filtroSucursal" onChange={handleChangeFiltroSucursal} />
        </div>
      </div>



      <div className="table">
        <div className="row" id="header">
          <div className="header">Fecha ingreso</div>
          <div className="header">Sucursal</div>
          <div className="header">Tipo</div>
          <div className="header">Estado</div>
          <div className="header"></div>
        </div>
        {currentRequests.map((solicitud) => armarSol(solicitud))}
      </div>
      {
        totalRequests > requestsPerPage && (
          <div className="pagination">
            <ul className="pagination-list">
              {Array.from({ length: Math.ceil(totalRequests / requestsPerPage) }, (_, index) => (
                <li
                  key={index}
                  className={`pagination-item ${index + 1 === currentPage ? "active" : ""}`}
                  onClick={() => handleChangePage(index + 1)}
                >
                  {index + 1}
                </li>
              ))}
            </ul>
          </div>
        )
      }
    </div >
  );
}
