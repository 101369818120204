import React, { useState, useEffect } from "react";
import DisplayScheduleService from "./DisplayScheduleService";
import "../../Estilos/PopUp.css";
import { getTipoServicioById } from "../Helpers/Formulas";
import CloseIcon from '@mui/icons-material/Close';

function SolicitudPendiente(props) {
  const [displayOpen, setDisplayOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [tipoServicioColor, tipoServicioTexto] = getTipoServicioById(props.tipo_servicio);

  useEffect(() => {
    if (displayOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [displayOpen]);

  const handleClick = () => {
    setDisplayOpen(true);
  };

  const handleClosing = () => {
    setIsClosing(true);
    setTimeout(() => {
      setDisplayOpen(false);
      setIsClosing(false);
    }, 300); // Tiempo de espera para que se complete la animación (ajusta según tus necesidades)
  };

  return (
    <div
      className="recuadro"
      style={{
        backgroundColor: "white",
        width: "90%",
        borderRadius: "20px",
        marginBottom: "5px",
        height: "15vh",
      }}
      onClick={handleClick}
    >
      <h4
        style={{
          fontFamily: "Dosis",
          backgroundColor: tipoServicioColor,
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        {tipoServicioTexto}
      </h4>

      <div className="cell" style={{ margin: "0 10px" }}>
        <p style={{ margin: "0", fontSize: "13px" }}>
          {props.cliente} - {props.direccion}
        </p>
      </div>

      {displayOpen && (
        <div className={`modal-overlay ${isClosing ? "fade-out" : "fade-in"}`}>
          <div className={`modal-content ${isClosing ? "slide-down" : "slide-up"}`}>
            <button className="close-button" onClick={handleClosing}>
              <CloseIcon className="close-icon" />
            </button>
            <DisplayScheduleService requestID={props.id} />
          </div>
        </div>
      )}
    </div>
  );
}

export default SolicitudPendiente;